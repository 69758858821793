import React, {createContext, useContext, useEffect, useState} from "react";
import {paper} from "paper/dist/paper-full.min";
export const DomContext = createContext({
  user:null,
  setUser:()=>{},
  domain:null,
  reg:false,
  setReg: ()=>{},
  regi:false,
  setRegi: ()=>{},
  page:null,
  setPage:()=>{},
  regs:0,
  setRegs:()=>{},
  fetchRegData: () =>{},
  lines: null,
getRegi:()=>{},
  setLines: ()=>{},
regis:null,
  setRegis: ()=>{},
  mero:null,
  setMero: ()=>{},
});


export const DomProvider = ({children}) => {

  const [user, setUser] = useState(false);
  const [lines,setLines]=useState([]);
  const [regis, setRegis] = useState(null);
  const [mero, setMero] = useState(null);
  const [reg, setReg] = useState(false);
  const [regs, setRegs] = useState(-1);
  const [regi, setRegi] = useState({});
  const [page, setPage] = useState(null);

// const telegram = useTelegramWebApp();


  /*



   grant_type: 'password',
        client_id: '2',
        client_secret: 'YfIZVVJH5OMQ1R4XDm8eC7BNG49q3L5lWVVlZjTy',
        username: e,
        password: p,
        scope: ''
   */
  async function logUser() {


    await fetch("https://buben-sha.herokuapp.com/oauth/token", {
      method: "POST",
      body: JSON.stringify({ grant_type: 'password',
        client_id: '2',
        client_secret: 'YfIZVVJH5OMQ1R4XDm8eC7BNG49q3L5lWVVlZjTy',
        username: 'ap@facereplays.com23',
        password: 'Pompon_22',
        scope: ''}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'

      }
    }).then(a => {
      console.log(a);
      // setUser(a);
    });
  }
  async function makeUser(user) {


    await fetch("https://buben-sha.herokuapp.com/api/register", {
      method: "POST",
      body: user
    }).then(a => {
      console.log(a);
      setUser(a);
    });
  }
  async function fetchUserDB(pro) {

    const response = await fetch("https://buben-sha.herokuapp.com/api/records/users/" + pro+"?exclude=password");
    await response.json().then(a=>{
      a &&setUser(a);

      }
    )
  }

  async function fetchUserData(pro) {

    const response = await fetch("https://buben-sha.herokuapp.com/api/records/users?filter=tgid,eq," + pro.id);
    await response.json().then(
      a => {
        if (a.records.length >= 1) {
          const r = a.records[0];
          r.username=pro.username;
          r.photo_url=pro.photo_url;
          r.userId=pro.id;
          localStorage.setItem('user',JSON.stringify(pro));
          document.cookie = 'userId='+pro.id+'; path=/; Expires=Thu, 01 Jan 2026 00:00:01 GMT;';
          // dom.setUser(a.records[0]);
          setUser(r);
        } else {

          const formData = new FormData();
          formData.append('email', pro.username+'@tele.tg');
          formData.append('password',pro.username+'_passw');
          formData.append('first_name', pro.username);
          formData.append('tgid', pro.id.toString());
          makeUser(formData);
          // makeUser({name: pro.username, email: 'tele@tele.dd', password: pro.username.'_passw',tgid:pro.id});

        }
        //  fetchUserFr();

        //  setUser(a.records[0]);
      }
    )

  }



  const [chat, setChat] = useState(0);
  const [ans, setAns] = useState(null);
  const params = new URLSearchParams(new URL(window.location.href).search.slice(1));
  const relative = !!Number(params.get("relative"));
  const coordinateSystem = params.get("coord");
const getRegi = ()=> regi;
  let box={top:0,bottom:0};
//  logUser();
  const initSensor =()=>{

    const options = { frequency: 60 };

    const sensor = relative ? new RelativeOrientationSensor(options) : new AbsoluteOrientationSensor(options);
    sensor.onreading = () => console.log(sensor.quaternion);
    sensor.onerror = (event) => {
      if (event.error.name == 'NotReadableError') {
        console.log("Sensor is not available.");
      }
    }
    // sensor.start();
  }

  useEffect(() => {
    setRegi({color:new paper.Color(localStorage.getItem('color')),size:localStorage.getItem('size'),brush:localStorage.getItem('brush')})

    if (navigator.permissions) {
      // https://w3c.github.io/orientation-sensor/#model
      Promise.all([navigator.permissions.query({ name: "accelerometer" }),
        navigator.permissions.query({ name: "magnetometer" }),
        navigator.permissions.query({ name: "gyroscope" })])
        .then(results => {
          if (results.every(result => result.state === "granted")) {
            initSensor();
          } else {
            console.log("Permission to use sensor was denied.");
          }
        }).catch(err => {
        console.log("Integration with Permissions API is not enabled, still try to start app.");
        initSensor();
      });
    } else {
      console.log("No Permissions API, still try to start app.");
      initSensor();
    }
    const telegram = window.Telegram;
//console.log(window.Telegram.WebApp);
    window.Telegram.WebApp.ready();
    box = window.Telegram.WebApp.isExpanded ? window.Telegram.WebApp.SafeAreaInset : {top:0,bottom:0};
    console.log(window.Telegram.WebApp.version); //
    Number(window.Telegram.WebApp.version) >=8 && window.Telegram.WebApp.requestFullscreen();

    window.Telegram.WebApp.disableVerticalSwipes();
    //  telegram.WebApp.expand();
    const store = telegram.WebApp.initDataUnsafe.user ? telegram.WebApp.CloudStorage : null;
    // telegram.WebApp.requestWriteAccess();
    //setChat(1);

    if (telegram.WebApp && telegram.WebApp.initDataUnsafe && telegram.WebApp.initDataUnsafe.user) {
      console.log(telegram.WebApp.initDataUnsafe.chat,telegram.WebApp.chat_instance);
      setChat(telegram.WebApp.initDataUnsafe.chat ? telegram.WebApp.initDataUnsafe.chat.id : telegram.WebApp.chat_instance ? telegram.WebApp.chat_instance : 0);

      const u = telegram.WebApp.initDataUnsafe.user;
      u.rights = false;
      u.fid = false;

      fetchUserData(telegram.WebApp.initDataUnsafe.user);



    }else{

      const tt = document.cookie.split('; ').map(a => a.split('=')).filter(a => {

        return a[0] == 'userId' ? true : false;

      });

    tt.length>0 && fetchUserDB(tt[0][1]);

    }

    /*
  if(telegram.WebApp.CloudStorage){
    setAns('a');
  store.getItem('key', (s,a)=>setAns(a));
  }else{  setAns('b');}

  */

    //   console.log(telegram.WebApp.initDataUnsafe.user);
    // ALTER TABLE member ALTER COLUMN tgid TYPE VARCHAR(255);
    //
  },[]);



 const upd = (u) => setUser(u);
  const value = {
    user,
    setUser,
    reg,
    setReg,
    regi,
    setRegi,
    page,
    setPage,
    regs,
    setRegs,getRegi,

   lines,setLines,regis,setRegis,mero,setMero
  };

  return (<div>

    <DomContext.Provider value={value}>
      {children}
    </DomContext.Provider>
  </div>)

};
export const useDom = () => useContext(DomContext);
