import React, {useEffect, useRef, useState} from "react";
import {createConnection} from "../../services/fetch";
import {useNavigate, useParams} from "react-router-dom";
import {paper} from "paper/dist/paper-full.min";
import {getStroke} from "perfect-freehand";
import Opts from "../../services/opts";
import Date from "moment/moment";


export default function SujetP(props) {
  const canvasRef=useRef(null);
  const [lines, setLines] = useState([]);
  const groups=useRef([]);
  const [tm, setTm] = useState(Date.now());
  const [rec, setRec] = useState(false);
  const [press, setPress] = useState('');
  let temp = 0;
  let se = 0;
  let currentLine = 0;
  const [line, setLine] = useState(0);
  const llns = [];
  const repl = () => {


    if(llns.length>0) {    temp += 25;

      const lns = llns.map((a, i) => {
        if (i === currentLine) {
          const st = a.points.filter(t => t.tm < temp);
          const o =  JSON.parse(JSON.stringify(a.opts));
          o.size = .5+ (a.opts.size-.5)*st.length/a.points.length;
          o.alpha=a.alpha-st.length/a.points.length*0.3;
          a.d = getSvgPathFromStroke(getStroke(st, o));
          const col = a.color=='' ? '#000' : a.color;
          const pa = '<path  opacity="' + a.alpha + '"    fill="' + col + '" d="'+a.d+'"/>';

          a.it.removeChildren();

          // @ts-ignore
          a.it.importSVG(pa, {insert: true});
          setPress(temp);
          if (a.delta < temp) {

            i == llns.length-1 && clearInterval(se);
            (i == llns.length-1) && drawThumb();
            setRec(true);
            currentLine++;
            temp = 0;

//console.log(lines);
          }
        }
        return a;
      })
      //console.log(llns.length, lns.length);
      // setLines(lns);
    }
  }
  const replay = () => {

    console.log('repl');
    currentLine = 0;
    //  setLines(strokes.map(a=>a.d=''));
    setTm(performance.now());
    se = setInterval(repl, 10);
    //setPress(press+1);
  }
  let results = 0;
  const pref = useRef(null);
  const {idS} = useParams();
  const [strokes, setStrokes] = useState([]);
  const [bunch, setBunch] = useState([]);
  const [page, setPage] = useState(null);
  const navigate = useNavigate();
  let pg=null;
  const ara = [1];
  const speed= 4;
  const objL = (o, st, ar = []) => {
    const obj=pg.objet[o].id;
    createConnection().get('strokes', 'filter=objet,eq,' + obj + "&page=" + st + ',20').then(a => {
      const recs = a.records.map(t => {


        if (t) {

          let step;

          const line = t.dots ? JSON.parse(t.dots.replace(/}/g, ']').replace(/{/g, '[')) : JSON.parse(t.line.replace(/\"(\d|\-)/g, '$1').replace(/\"]/g, ']').replace(/\",/g, ','));
          if(line.length >1 ) {
            if (t.delta) {
              step = t.delta / line.length;

            } else {
              //  const ll = t.dots ? t.dots.replace(/{{/g, 'M ').replace(/,([0-9|\.]+)}}/g, '').replace(/,([0-9|\.]+)},{/g, ' L ') :
              //  t.line.replace(/\[\[/g, 'M ').replace(/,([0-9|\.]+)\]\]/g, '').replace(/,([0-9|\.]+)\],\[/g, ' L ');
              //   pref.current.setAttribute('d', ll);

              step = 400 / line.length;
            }

            const black = t.color.replace('rgba(', '').replace(')', '').split(',').map(a => a / 255);

            const l = Math.max(black[0], black[1], black[2]);
            const s = l - Math.min(black[0], black[1], black[2]);
            t.sat = 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0);
            t.color = t.color.match(/#/) ? t.color : t.color.replace('%23','#');
            const rt = line.map((l, y) => {
              const kl = {};
              const ttm = y === 0 ? 0 : Math.round(y * step);
              kl.x = l.x ? l.x : l[0];
              kl.y = l.y ? l.y : l[1];
              kl.pressure = l.pressure ? l.pressure : l[2];
              kl.tm = ttm;

              return kl;

            });
            const opts = new Opts(t.width ? t.width : 3, t.type);

            t.dd = getSvgPathFromStroke(getStroke(line, opts));
            const gr = groups.current[groups.current.length - 1];
            const it = new paper.Group({id: t.id});
            gr.addChild(it);
            t.deltaG = pg.objet[o].delta ? pg.objet[o].delta : null;
            t.d = '';
            t.it = it;
            t.points = rt;
            t.opts = opts;

            llns.push(t);

            //requestAnimationFrame(drawFrame);

            return t; }}
      });

      //const opts = new Opts(size,brush);

      const as = ar.concat(recs);

      // console.log(as);
      (st == 1 && !ara.includes(st + 1)) && console.log('loading')
      if (!ara.includes(st + 1)) {
        setStrokes(as);
      } else console.log('dddddoub', st, ara);

      if (a.records.length == 20 && !ara.includes(st + 1)) {
        //  st == 1 && replay();
        results = st == 1 ? results + a.results : results;
        ara.push(st + 1);
        objL(o, st + 1, as);
      } else {

        if(pg.objet.length>o+1){
          const gr=new paper.Group();
          gr.applyMatrix=false;
          const a=groups.current;
          a.push(gr);
          groups.current=a;
          if(pg.objet[o+1].transforms && pg.objet[o+1].transforms.length>0){
            const nu= JSON.parse(pg.objet[o+1].transforms)[0];
            if(nu) {
              const mtx = new paper.Matrix(nu[2], 0, 0, nu[2], nu[3], nu[4]);
              console.log(mtx);
              gr.transform(mtx);
            }
          }
          paper.project.activeLayer.children[0].addChild(gr);
          objL(o+1, 1, as);

        }
      }
    });
  }
  const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  const drawThumb= ()=>{
    createConnection().thumb(canvasRef.current,pg.id);

    // console.log(c);
  }
  useEffect(() => {


    if (!page && idS) {
      createConnection().get('sujet/' + idS, 'join=objet&exclude=code').then(a => {
          paper.setup(canvasRef.current);




          const gr=new paper.Group();
          groups.current=[gr];
          gr.applyMatrix=false;
          if(a.objet[0].transforms && a.objet[0].transforms.length>0) {
            const nu = JSON.parse(a.objet[0].transforms)[0];
            console.log(nu);
            if(nu) {

              const sc=  typeof nu[2] === 'Array' ? nu[2] : nu[2][0];
              const mtx = new paper.Matrix(sc, 0, 0, sc, nu[3], nu[4]);
              gr.transform(mtx);
            }
          }
          a.size=a.size.split(',');
          a.coef=a.size[0]/a.size[1];
          const sc=  a.coef>1 ? window.innerWidth*0.9/a.size[0] : window.innerHeight*0.9/a.size[1];
          a.w= a.coef>1 ? Math.round(window.innerWidth*0.9) : Math.round(window.innerHeight*0.9*a.coef)
          a.h= a.coef<=1 ? Math.round(window.innerHeight*0.9) : Math.round(window.innerWidth*0.9/a.coef)
          const initGroup= new paper.Group();
          initGroup.applyMatrix=false;
          initGroup.scale(sc);
          paper.project.activeLayer.addChild(initGroup);
          initGroup.addChild(gr);
          pg=a;

          setPage(a);
          objL(0, 1);
          replay();
          //   requestAnimationFrame(drawFrame);
          //a.objet.map(u => )
          //objL(a.objet[0].id, 1);

        }
      );

    }


  });
  return props.st ?
    (
      <div className=" w-40 h-40 p-2"
           style={{backgroundSize: 'cover', backgroundImage: props.st.thumb ? 'url(' + props.st.thumb + ')' : 'none'}}
           onClick={() => navigate('/sujet/' + props.st.id)}>

        {props.st.name}<br/>


      </div>
    )
    :
    (
      <div style={{backgroundColor: '#eee',width:'100vw',height:'100vh'}}>




        <canvas style={page ? {margin:'auto',height:page.h+'px',width:page.w+'px'} : {margin:'auto',height:'100%',width:'100%'}} ref={canvasRef} width={page ? page.w : window.innerWidth} height={page ? page.h : window.innerHeight}>


        </canvas>
        {page && (

          <div className='text-center mx-auto'>{page.name}</div>

        )}

      </div>
    )


}
