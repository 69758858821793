import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";

import { getSignedUrl } from "@aws-sdk/s3-request-presigner";




export function MyS3() {

  return {

   async thumb(path,myFile,type)  {


     const S3 = new S3Client({
       region: "auto",
       endpoint: process.env.REACT_APP_AWS_ENDPOINT,
       credentials: {
         accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
         secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
       },
     });




       getSignedUrl(
       S3,
         new PutObjectCommand({
           Bucket: 'vids',
           Key: path,
         }),
         {
           expiresIn: 60 * 60 * 24 * 7, // 7d
         }
       ).then((url) =>{


         const requestOptions = {
           method: 'PUT',
           headers: { 'Content-Type':type },
           body: myFile
         };
         fetch(url, requestOptions)
           .then(data => console.log(data));
         /*
         http.put(url,
           myFile,
           {
             headers: new HttpHeaders({
               'Content-Type': type
             })
           }).subscribe(ed=>{

           console.log(ed);

         });
     */

       })



    }
  }
}
