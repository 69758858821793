import React, {Fragment, useEffect, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import {Dialog,  Transition} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Date from "moment";
import {Dialogo, DialogP} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
import {useLoaderData, useParams} from "react-router-dom";
import CanvasKitInit from "../js/canvaskit";
import paper from "paper";
import {Line, LinesC} from "../services/lines";
import Menu from "./menu";
import MenuM from "./menu";
import {MyS3} from "../services/context";
import {Shaders} from "../services/shaders";


const getSvgPathFromStroke = (stroke)=> {
  if (!stroke.length) {
    return '';
  }

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ['M', ...stroke[0], 'Q']
  );

  d.push('Z');
  return d.join(' ');
}
const Pa = (o)=>{
  const [d, setD] = useState('');
  const [str, setStr] = useState(null);
const t= o.line;


   if(t && t.dots) {


     const line = JSON.parse(t.dots.replace(/}/g, ']').replace(/{/g, '['));
     const opts = new Opts(t.width ? t.width : 4, t.type);
     setD(getSvgPathFromStroke(getStroke(line, opts)));

    // setTimeout(() => o.in(o.tm + 1), 400);
   }



  return (
    <>
    {d && (
        <path d={d} fill={t.color}/>

      )
    }
</>
  )
}

export default function Sket() {
  const fg=Shaders().d;
  let sor='';
  let ck;
const  read =async (a)=>{
//const so=
 sor += String.fromCharCode.apply(null, a);
}
  const data = useParams();
const id=data.id ? data.id :500;
const [ttm, setTtm] = useState(Date.now());

//  const [line, setLine] = useState({points:[],d:''});
  const [speed, setSpeed] = useState(32);
  const sujet= useRef(null);
  const [inte, setInte] = useState(0);
  const [dia, setDia] = useState(false);
  const myCan = useRef(null);
let hi=0;
let wi=0;
  let hh=0;
  let ww=0;
let widthR=0;
let surface;
let su;
let tm=0;
let rrb;
let bgImage;
  let shader1;
let paperB;
  const rootElement = document.getElementById("ttid");
  paper.setup([100,100]);
  let coef = 1;
  const linesC = new LinesC();
  let line=null;
  let paint;
  let paintB;
  let paintA;
  let paintC;
  let surfV;
  let suV;
  let shdF;
  let shdS;

 const  fetchFont   = async(ck,hh) =>{
    let robotoData = await fetch(
      "https://storage.googleapis.com/skia-cdn/google-web-fonts/Roboto-Regular.ttf"
    ).then((resp) => resp.arrayBuffer());



 const fontMgr = ck.FontMgr.FromData([robotoData]);

 const   paraStyle = new ck.ParagraphStyle({
    textStyle: {
        color: ck.Color(0, 0, 0, .5),
        fontFamilies: ["Roboto"],
        fontSize: hh>600 ? 16 : 12
      },
      strutStyle: {
        strutEnabled: true,
        fontFamilies: ["Roboto"],
        fontSize: hh>600 ? 16 : 12,
        heightMultiplier: 1.3,
        forceStrutHeight: true
      },
      textAlign: ck.TextAlign.Center,
      maxLines: 7,

      ellipsis: "..."
    });

    return { fontMgr, paraStyle };
  }




 let paragraph;

  const lines=[];
  const getObj = (ob,obj,i, ar = [])=>{


      createConnection().get('strokes','filter=objet,eq,'+obj[ob].id+"&page="+i+',20').then(a=> {
        const recs = a.records.map(u=>{
u.line= u.line!='[]'  ?   JSON.parse(u.line) : JSON.parse(u.dots.replace(/{/g,'[').replace(/}/g,']'));
//console.log(u.line);
u.lineD=u.line.map(h=>{

return !h.x ? {x:h[0]*coef,y:h[1]*coef,pressure:h[2]} : h.map(g=> {return {x:g.x*coef,y:g.y*coef,pressure:g.pressure}});

});
//console.log(u.lineD);
//u.opts=new Opts(u.size ? u.size:1,u.type);

          u.opts={
            size: u.width ? 0.7 * u.width : 0.2, thinning: 1,
            simulatePressure: false
          }
if(!u.delta || u.delta>1000){
          const ln =new paper.Path(u.lineD.map(a =>  [a.x,a.y]));
         u.delta=ln.length;
        }

       u.d= getSvgPathFromStroke(getStroke(u.lineD,u.opts));

const col=u.color.match(/([\d|,|\.]+)/) ? u.color.match(/([\d|,|\.]+)/)[0].split(',') : [0,0,0,1];
u.color= new ck.Color(col[0],col[1],col[2],col[3]);
u.col=col.map(c=>Number(c));
          const paint2 = new ck.Paint();
          paint2.setColor(u.color);
          paint2.setStyle(ck.PaintStyle.Fill);
          paint2.setAntiAlias(true);
   u.paint=paint2;
   lines.push(u);
return u;

        });
     if (i==1 && ob==0 ) {
     const u = recs[0];

    line = new Line(u.width ? u.width : 1, u.color, u.alpha, u.opts, 0,u.id);
 // console.log(line);

     }
      //console.log(as);
        a.records.length == 20 ? getObj(ob,obj,i+1,[]) : ob+1 < sujet.current.objet.length ? getObj(ob+1,obj,1,[]) : null;
      });
    }
const drawFrame = (sum) =>{
    let done = 0;
    let l=0;
if(line) {
//  su.drawRRect(rrb, paintC);
  lines.map((r, i) => {

    if (r.id === line.id) {

      const d = getSvgPathFromStroke(getStroke(r.lineD.filter((s, y) => {

        return y / r.lineD.length < tm / r.delta ? true : false
      }), r.opts));
      let firstPath = ck.Path.MakeFromSVGString(d);
      //firstPath.stroke({width:1});

      /***
       line added



       */
      su.drawPath(firstPath, r.paint);
      const im2 = surface.makeImageSnapshot([0, 0, ww, hh]);
     shader1 = im2.makeShaderOptions(
        ck.TileMode.Repeat,  // Tile the image in both X and Y directions
        ck.TileMode.Repeat,
        ck.FilterMode.Linear,
        ck.MipmapMode.Nearest,
        ck.Matrix.identity()
      );
/***
 shader1 contains line

 */
      im2.delete();
      //console.log(paperB,bgImage,shader1);
su.clear();
      paintC.setShader(shader1);
     // su.clear();
   //   su.drawRRect(rrb, paintB);
     // su.drawRRect(rrb, paintC);

      r.done = tm > r.delta ? 1 : 0;
      done = r.done;
     // console.log(done);
      if (tm > r.delta) {
        if (i < lines.length - 1) {
          const u = lines[i + 1];
          line = new Line(u.width ? u.width : 1, u.color, u.alpha, u.opts, 0, u.id);
          l=1;


          const shh = shdS.makeShaderWithChildren(new Float32Array([.2]),[shader1, bgImage], [
            1, 0, 0, 0,
            0, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1]);

          paintA.setShader(shh);

         su.drawRRect(rrb, paintA);

          const im3 = surface.makeImageSnapshot([0, 0, ww, hh]);
         su.clear();
          bgImage = im3.makeShaderOptions(
            ck.TileMode.Repeat,  // Tile the image in both X and Y directions
            ck.TileMode.Repeat,
            ck.FilterMode.Linear,
            ck.MipmapMode.Nearest,
            ck.Matrix.identity()
          );

          //
paintC.setShader(bgImage);
          //im1.delete();
          im3.delete();
         // su.clear();
          tm = 0;
        } else {


        }

      }
    }
    // const rrb = ck.LTRBRect(0, 0,150-tm, 30);


    // su.drawRRect(rrb, paint);
    //console.log(r.d);


  });
/*
  const shn = shdS.makeShaderWithChildren(new Float32Array([.2]),[shader1, bgImage], [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1]);

  paintC.setShader(shn);

*/

  const sh = shdF.makeShaderWithChildren(new Float32Array([0.2, Date.now() - line.startT, line.color[0], line.color[1], line.color[2], ww, hh]), [paperB, bgImage, shader1], [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1]);
 paintC.setShader(sh);
  su.drawRRect(rrb, paintC);
paragraph &&  paragraph.layout(300);
const fo=hh>600 ? 16 : 10;
  paragraph &&  su.drawParagraph(paragraph, ww/2-150, hh-fo*3);
  //su.drawRRect(rrb, paintC);
if(done == 1 && !sujet.current.thumb) {
  const image = surface.makeImageSnapshot([0, 0, ww, hh]);
  const pngData = image.encodeToBytes();
  const path = 'f_' + sujet.current.id.toString() + '.png';
  const blob = new Blob([pngData],{ type: 'image/png' });

  const myFile = new File([blob], path, {
    type: blob.type,
  });
  const tt = MyS3().thumb(path, myFile, 'image/png');
}
}
 if(done==0) {surface.requestAnimationFrame(drawFrame);} else{

 }
 if(lines.length>0) tm+=speed;
//if(tm<209)
setTtm(Date.now());
}
let paintF;
  let hhR;
  let font;
  let htmlCanvasElement;
useEffect(() => {
  const rootElement = document.getElementById("ttid");
  wi= rootElement.clientWidth;
  hi= rootElement.clientHeight;
  const CanvasKitInit = require('../js/canvaskit')


  CanvasKitInit().then((CanvasKit) => {
    ck=CanvasKit;


    if (!sujet.current) {


      createConnection().get('sujet/' + id, 'join=objet,strokes&include=thumb,story,name,size,totaltime,strokes.id').then(a => {


          const size = a.size.split(',');
          let w = Number(size[0]);
          size.length > 2 ? w -= Math.min(0, Number(size[2])) : null;
          const h = Number(size[1]);
          const totL = a.totaltime;
if(h>w){
          if (hi / wi >= h / w) {

            coef = (wi / w);
          hhR = (hi / wi * w - h) / 2;
          } else {

coef = hi /h;
}
      }else{

  if (hi / wi >= h / w) {
    console.log('r');
    coef = (wi / w);
    hhR = (hi / wi * w - h) / 2;
  } else {

    coef = hi /h;
  }


}

          hh=h*coef;
          ww=w*coef;

         paintF = new ck.Paint();
          paintF.setColor(ck.Color(0, 0, 0, .5)); // Black color
          paintF.setAntiAlias(true);
          paintF.setStyle(CanvasKit.PaintStyle.Fill);

         font = new ck.Font(null, 16); // Default typeface, font size 40


         htmlCanvasElement = document.createElement("canvas");

        htmlCanvasElement.width = ww;
        htmlCanvasElement.height = hh;
        htmlCanvasElement.id='foo';
        rootElement.appendChild(htmlCanvasElement);
       surfV = CanvasKit.MakeCanvasSurface(new OffscreenCanvas(ww, hh));
        suV=surfV.getCanvas();
        surface = CanvasKit.MakeCanvasSurface('foo');
        su=surface.getCanvas();
        paint = new CanvasKit.Paint();
        paint.setColor(CanvasKit.Color4f(0.12,.12,.1, 1));
        paint.setStyle(CanvasKit.PaintStyle.Fill);
        paint.setAntiAlias(true);
        paintB = new CanvasKit.Paint();
        //paintB.setColor(CanvasKit.Color4f(.0,.0,.0,.0));
        paintB.setStyle(CanvasKit.PaintStyle.Fill);
        paintB.setAntiAlias(true);
          paintA = new CanvasKit.Paint();
         paintA.setStyle(CanvasKit.PaintStyle.Fill);
          paintA.setAntiAlias(true);
        paintC = new CanvasKit.Paint();

        paintC.setStyle(CanvasKit.PaintStyle.Fill);
        paintC.setAntiAlias(true);
          const im1 = surface.makeImageSnapshot([0, 0, ww, hh]);

          bgImage = im1.makeShaderOptions(
            ck.TileMode.Repeat,  // Tile the image in both X and Y directions
            ck.TileMode.Repeat,
            ck.FilterMode.Linear,
            ck.MipmapMode.Nearest,
            ck.Matrix.identity()
          );
//paintB.setShader(shader1);
          im1.delete();


          fetchFont(ck,hh).then(f=>{
            createConnection().get('story/' + a.story, 'join=user').then(g => {




const  builder = CanvasKit.ParagraphBuilder.Make(f.paraStyle,f.fontMgr);
           builder.addText(a.name+' @ '+g.name+'\nEsketchos.art©2025');

          paragraph = builder.build();

          });

      })

        fetch(require('../static/paper.png'))
          .then((response) => response.arrayBuffer())
          .then((buffer) => {
            // console.log(new Uint8Array(buffer));
            const bgg = CanvasKit.MakeImageFromEncoded(new Uint8Array(buffer));

            paperB =  bgg.makeShaderOptions(
              CanvasKit.TileMode.Repeat,  // Tile the image in both X and Y directions
              CanvasKit.TileMode.Repeat,
              CanvasKit.FilterMode.Linear,
              CanvasKit.MipmapMode.Nearest,
              CanvasKit.Matrix.identity()
            );

            rrb = CanvasKit.LTRBRect(0, 0,ww,hh);
           // paintB.setShader(bgImage);
            //su.drawRRect(rrb, paintB);
            bgg.delete();

            size.length > 2 ? widthR -= Math.min(0, Number(size[2])) : null;

            const coMat = new paper.Matrix(coef, 0, 0, coef, 0, 0);
            sujet.current = a;
             shdS = CanvasKit.RuntimeEffect.Make(`
  uniform shader uImage;
  uniform shader uImage1;
uniform float ff;
vec4 main(vec2 pos) {
vec4 sum = vec4(0.0);
float h = 1.2;
sum += uImage.eval( vec2( pos.x - 4.0 * h, pos.y ) ) * 0.051;
sum += uImage.eval( vec2( pos.x - 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x - 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x - 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x, pos.y ) ) * 0.1633;
sum += uImage.eval( vec2( pos.x + 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x + 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x + 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x + 4.0 * h, pos.y ) ) * 0.051;

//vec4 sum = uImage.eval(pos);
vec4 sum1 = uImage1.eval(pos);
float s=sum1.a>=1 ? sum.a : sum1.a;
return vec4(vec3(max(sum.r,sum1.r),max(sum.g,sum1.g),max(sum.b,sum1.b)),sum.a+sum1.a);
//return sum;
}
  `);

             shdF = CanvasKit.RuntimeEffect.Make(`
   vec3 hash( vec3 p ) // replace this by something better
{
p = vec3( dot(p,vec3(127.1,311.7, 74.7)),
dot(p,vec3(269.5,183.3,246.1)),
dot(p,vec3(113.5,271.9,124.6)));
return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}
float noise(vec3 p )
{
    vec3 i = floor( p );
    vec3 f = fract( p );
vec3 u = f*f*(3.0-2.0*f);
 return mix( mix( mix( dot( hash( i + vec3(0.0,0.0,0.0) ),
                           f - vec3(0.0,0.0,0.0) ),
  dot( hash( i + vec3(1.0,0.0,0.0) ), f - vec3(1.0,0.0,0.0) ), u.x),
  mix( dot( hash( i + vec3(0.0,1.0,0.0) ), f - vec3(0.0,1.0,0.0) ),
 dot( hash( i + vec3(1.0,1.0,0.0) ),
     f - vec3(1.0,1.0,0.0) ), u.x), u.y),
 mix( mix( dot( hash( i + vec3(0.0,0.0,1.0) ), f - vec3(0.0,0.0,1.0) ),
 dot( hash( i + vec3(1.0,0.0,1.0) ), f - vec3(1.0,0.0,1.0) ), u.x),
mix( dot( hash( i + vec3(0.0,1.0,1.0) ), f - vec3(0.0,1.0,1.0) ),
dot( hash( i + vec3(1.0,1.0,1.0) ),
    f - vec3(1.0,1.0,1.0) ), u.x), u.y), u.z );
}
float simpleNoise(vec2 uv,float freq) {
    return noise(vec3(freq * uv.xy, 1))*0.5 +0.5;
}
float hash(vec2 p) // Dave H
{
vec3 p3  = fract(vec3(p.xyx) * .1031);
    p3 += dot(p3, p3.yzx + 33.33);
    return fract((p3.x + p3.y) * p3.z);
}
float paper (vec2 U) { //https://www.shadertoy.com/view/NsfXWs
    float h = .005*(sin(.6*U.x+.1*U.y)+sin(.7*U.y-.1*U.x));
    for (float x = -1.; x<=1.;
x++)
    for (float y = -1.; y<=1.;
y++){
        h += .15*.125*hash(U+vec2(x,y));
    }
    return h;
}

uniform shader paperB;
uniform shader uImage;
uniform shader bgImage;
uniform float ff;
uniform float tm;
uniform float r;
uniform float g;
uniform float b;
uniform float wi;
uniform float hi;

vec4 main(vec2 pos) {
//

vec4 bg = vec4(0.0);
vec4 bgP = vec4(0.0);
vec4 sum = vec4(0.0);
float h = 2;
sum += uImage.eval( vec2( pos.x - 4.0 * h, pos.y ) ) * 0.051;
sum += uImage.eval( vec2( pos.x - 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x - 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x - 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x, pos.y ) ) * 0.1633;
sum += uImage.eval( vec2( pos.x + 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x + 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x + 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x + 4.0 * h, pos.y ) ) * 0.051;

sum = uImage.eval( vec2( pos.x, pos.y ));
bg = bgImage.eval( vec2( pos.x, pos.y));
bgP = paperB.eval( vec2( pos.x, pos.y));

float w =180+tm/50;
vec2 uv = pos / vec2(wi,hi);
  uv = vec2(uv.x*2-1,uv.y*2-1);
   uv.x *= wi / hi;

  int f1=int(floor(ff));
  vec3 ffb=vec3(0.0);
  float ret=sum.r;
 // float ret=smoothstep(.0,1.0,pow(sum.r,3));
//ret=sum.r<.7 ? 0 : cos((1-sum.r)*12);




float no=ret+simpleNoise(uv,60)*0.1;
 float largeNoise = simpleNoise(uv, 12);
no -= 0.1 * smoothstep(0.35, 1, largeNoise);

no=pow(clamp(no,0.0,1.0),2);

float wetNoiseFreq = 3.0;
    float wetNoiseStrength = 0.15;
    float wetNoise = simpleNoise(uv, wetNoiseFreq);
    float wetNoiseStep = smoothstep(0.25, 0.25 + wetNoiseStrength, wetNoise);
    float wetness = clamp(wetNoiseStep, 0.0, 1.0);



 float value;
    float valueSlim;float edge1;
    if (no < 0.0) {
        // no change in the center circle
        value = no;
        valueSlim = 1.0;
        edge1=1.0;
    } else {
        const float aaDelta = 0.006; // add a small delta to reduce aliasing
        const float edgeWet = 0.24;
       float edgeDry = 0.08;
         edge1 = mix(edgeDry, edgeWet, wetness) + aaDelta;
        value =     smoothstep(edgeDry, edge1, no);
        valueSlim = pow(1.0 - smoothstep(0.0, edge1, no), 0.15);
    }





value = value + 0.5 * valueSlim;

  float postNoise = simpleNoise(pos, .5);
    float ssStrength = .05;
    float postNoiseAmount = 0.5 - wetNoise;
    value = value + value *  postNoiseAmount * smoothstep(0.3, postNoise * ssStrength, value);

    float paper = paper(pos*10);
    float noiseCol = noise(vec3(2.0 * uv.x, 2.0 * uv.y, .4))*.5+.5;
    float gradient =0.5 * (uv.x + uv.y);
    //vec3 distCol = mix(vec3(r, g, b), vec3(noiseCol), vec3(r , g, b));
  vec3  distCol=vec3(r, g, b)*noiseCol+vec3(r*.1, g*.1, b)*(1-noiseCol);
    vec3 colors = mix(distCol, vec3(1.0,1.0,1.0), value*1.2 + paper * 1);
  //  fragColor = vec4(colors,1) ;
vec4 ff=vec4(vec3(max(sum.r,bg.r),max(sum.g,bg.g),max(sum.b,bg.b)),sum.a+bg.a-paper*.8);
//return vec4(vec3(1-paper),1);
return ff;
//return vec4(vec3(-sum.r*2+bg.r-bgP.r*.1),1);
//return vec4(vec3(pow(ret+max(0,no),0.7+min(0.5,tm/10000))),1);
//return  uImage.eval(pos);
 }`);
 getObj(0,a.objet,1);
            surface.requestAnimationFrame(drawFrame);

          });






        // su1.drawRRect(rrb, paint2);



        }
      );

    }
  })


},[])

  return (
    <>
<MenuM/>

    </>
  )
}
