import React, {Fragment, useEffect, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Date from "moment";
import {Dialogo, DialogP} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
//import {Store} from "../services/store";

import Story from "./thumbs/story";
import Carousel from "./carousel/Carousel";
import {useDom} from "../context/dom";
export default function Esketchos(props) {



  const store= useCloudStorage()
 // const store = Store();
  const [rec, setRec] = useState(false);
  const [open, setOpen] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [size,setSize]= useState(0);
  const [brush,setBrush]= useState('pen');
  const [color,setColor]= useState('0x000000');
  const [alpha,setAlpha]= useState(.5);
  const [page, setPage] = useState(null);
  const [w, setW] = useState(window.innerWidth);
  const [draw, setDraw] = useState(false);
  const [tm, setTm] = useState(Date.now());
  const [lines, setLines] = useState([]);
  const [line, setLine] = useState({points:[],d:''});
  const [press, setPress] = useState('');
  const [sujet, setSujet] = useState(null);
  const [inte, setInte] = useState(0);
  const [dia, setDia] = useState(false);
  const myCan = useRef(null);
  const dom = useDom();
let temp=0;
let se=0;
let currentLine =0;
  window.addEventListener("resize", ()=>setW(window.innerWidth));
  const [initDataUnsafe, initData] = useInitData();
  if(size==0){
if(initDataUnsafe.user){
  store.getItem('color').then((b)=>{
    setColor(b);
  });
  store.getItem('size').then((b)=>{
    setSize(b);
  });
  store.getItem('brush').then((b)=>{
    setBrush(b);
  });
}else {
  setColor(localStorage.getItem('color'));
  setSize(localStorage.getItem('size') ? localStorage.getItem('size') : 2);
  setBrush(localStorage.getItem('brush'));
}
}
  const getSvgPathFromStroke = (stroke)=> {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  const repl=()=>{
    temp+=3;

    lines.map((a,i)=>{
if(i===currentLine){


  a.d=getSvgPathFromStroke(getStroke(a.points.filter(t => t.tm<temp),a.opts));
 // console.log(a.d);
  setPress(temp);
  if(a.end<temp) {
    i==lines.length-1 && clearInterval(se);

    setRec(true);
    currentLine++;
 temp=0;

//console.log(lines);
  }
}

    })
setLines(lines);
  }
  const replay=()=>{

currentLine=0;
setLines(lines.map(a=>a.d=''));
setTm(Date.now());
 se = setInterval(repl,2);
    //setPress(press+1);
  }
const del=()=>{
setDia(true);

}
  const undo=()=>{
    lines.pop();
    setLines(lines);
    setPress(press+1);
  }

const ptMove=(e)=>{

 if(draw)  {
 setPress(press+1);
 const co = e.width == 0  ? e.pointerType === 'pen' ? 10 : 20 : e.pointerType === 'pen' ? 10 : 1;
 if(line){
line.points.push({x:e.clientX,y:e.clientY,pressure:e.pressure*co,tm:Date.now() - line.startT });

 line.d=  getSvgPathFromStroke(getStroke(line.points,line.opts));
setLine(line);
 }
 }
}
  const fub = (e)=> {
  console.log(e);
    setColor(e.color);
    setSize(e.size);
    setBrush(e.brush);
  }
  const handleDown = (e)=>{
    setPress(e.pointerType);
setDraw(true);
setTm(Date.now());
const totSt=lines[0] ? lines[0].start : Date.now();
    const opts = new Opts(size,brush);
setLine({points:[],d:'',start: Date.now() - totSt ,startT:Date.now(),opts:opts,alpha:alpha,color:color,size:size});


 }
 const delR = ()=>{
    setLines([]);
    setDia(false);
 }
  const handleUp = (e)=>{
    localStorage.getItem('size')


   setTimeout(()=>setDraw(false),200);


//console.log(getSvgPathFromStroke(getStroke(line.points)));
line.d= getSvgPathFromStroke(getStroke(line.points,line.opts));

line.end=Date.now() - line.startT;
lines.push(line);


lines.map((a,i)=>{
const points = a.points.map(u=>{

return [Math.floor(u.x*1000)/1000,Math.floor(u.y*1000)/1000,Math.floor(u.pressure*100)/100,u.tm]

})
//console.log(points,JSON.stringify({col:a.color,s:a.size,b:a.brush,p:points}).length);
})
setLine(null);
  }
  const handleLeave = (e)=>{
    setDraw(false);
  }


  useEffect(() => {
console.log(props.pro);
  if(!sujet)   {
    createConnection().get('story','filter=user,eq,11&join=sujet&include=id,name,sujet.name').then(a=> {
        setSujet(a.records);
        console.log( a.records)
      }
    );
    setSujet([]);
  }

  },[]);


  return (
    <div>

      {
        (sujet &&  sujet.length > 0) ?
          (
            <>
              {w<480 ? (
                <div ref={myCan} className="h-screen bg-gray-50 lock w-screen">
                  <Carousel>
                    {sujet.map((a, i) =>
                      (


                        <Story w={0} key={a.id} st={a}/>

                      )
                    )


                    }
                  </Carousel>
                </div>


              ) : (

                <div ref={myCan} className="flex flex-wrap h-screen bg-gray-50 lock w-screen">

                    {sujet.map((a, i) =>
                      (


                        <Story w={w} key={a.id} st={a}/>

                      )
                    )


                    }

                </div>

              )}

            </>
          ) : (
            ''
          )

      }


    </div>
  );
}
