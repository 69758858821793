import React, {Fragment, useEffect, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Date from "moment";
import {paper} from "paper/dist/paper-full.min";
import {Dialogo, DialogP} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
//import {Store} from "../services/store";
import {Line,LinesC} from "../services/lines";
//import {Paint} from "../services";
const  EsketC= (pro)=>{

//console.log('ddd');
  const CanvasKit = pro.prop;
  console.log(CanvasKit);
 // const can1 =CanvasKit.MakeSurface(400,600);

  const can1 = CanvasKit.MakeCanvasSurface('foo1');
  const surface = CanvasKit.MakeCanvasSurface('foo');
  const su=surface.getCanvas();
  const su1=  can1.getCanvas();
 // const su2=surface.getCanvas();
  const paint = new CanvasKit.Paint();
  paint.setColor(CanvasKit.Color4f(0,0, 0, .43));
  paint.setStyle(CanvasKit.PaintStyle.Fill);
  paint.setAntiAlias(true);
  const paint1 = new CanvasKit.Paint();
 paint1.setColor(CanvasKit.Color4f(0.99,1,1,1));
  paint1.setStyle(CanvasKit.PaintStyle.Fill);
  paint1.setAntiAlias(true);
  const paint2 = new CanvasKit.Paint();
  paint2.setColor(CanvasKit.WHITE);
  paint2.setStyle(CanvasKit.PaintStyle.Fill);
  paint2.setAntiAlias(true);
  const rootElement = document.getElementById("ttid");
  const tOLeft =  useRef(null);
  const xy=[];
  xy[0]=rootElement.clientWidth;
  xy[1]= rootElement.clientHeight;
  tOLeft.current = rootElement.offsetLeft;
  const rrb = CanvasKit.LTRBRect(0, 0, rootElement.clientWidth, rootElement.clientHeight);

 // su1.drawRRect(rrb, paint2);
  su1.drawRRect(rrb, paint2);
  //su.drawRect(CanvasKit.LTRBRect(10, 10, 100, 100), paint);
 const store= useCloudStorage();
 // const store = Store();
  const [type, setType] = useState(false);
  const [rec, setRec] = useState(false);
  const [open, setOpen] = useState(false);
  const [openP, setOpenP] = useState(false);
  const size =  useRef(10);
  const brush =  useRef('pen');
  const color =  useRef('#ff0033');
  const [alpha,setAlpha]= useState(.5);
  const [page, setPage] = useState(null);
  const draw = useRef(false);

  const tm =  useRef(Date.now());
  const lines = new LinesC();
let line=null;
let tms =0;
  const [press, setPress] = useState('');
  const [sujet, setSujet] = useState([]);
  const [inte, setInte] = useState(0);
  const [dia, setDia] = useState(false);
  const myCan = useRef(null);
  const draww = useRef(false);
let temp=0;
let se=0;
let currentLine =0;
  const [initDataUnsafe, initData] = useInitData();

  const getSvgPathFromStroke = (stroke)=> {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  const repl=()=>{
    temp+=3;

    lines.map((a,i)=>{
if(i===currentLine){


  a.d=getSvgPathFromStroke(getStroke(a.points.filter(t => t.tm<temp),a.opts));
 // console.log(a.d);
  setPress(temp);
  if(a.end<temp) {
    i==lines.length-1 && clearInterval(se);
    //stopRecording();
    setRec(true);
    currentLine++; temp=0;

//console.log(lines);
  }
}

    })
//setLines(lines);
  }

const del=()=>{
setDia(true);

}
  const undo=()=>{
    lines.lines.pop();
    //setLines(lines);
    setPress(press+1);
  }

const ptMove=(e)=>{

 if(draw.current)  {

// setPress(press+1);
 let co = e.width == 0  ? e.pointerType === 'pen' ? 10 : 20 : e.pointerType === 'pen' ? 10 : 2;
   e.pointerType === 'touch' ? co=0.1 : co=co;
   //console.log(e.width,e.pressure,co,e.pointerType);
 if(line){
 //  console.log(e.clientX,);
   line.points.push({len:0,x:e.clientX - tOLeft.current,y:e.clientY,pressure:brush.current == 'aqua' ? 0.01 : e.pressure*co, pre:e.pressure*co,ttm:0,tm:Date.now() - line.startT });
   line.points.map((a,i)=>{ a.pressure = a.pressure<5 ? a.pressure+ .05 : 5;
  line.done= a.pressure<5 ? false : true;
 if(i>0){
   const l=line.points[i-1];
    a.len =Math.sqrt((a.x -l.x)*(a.x -l.x) +  (a.y-l.y)*(a.y -l.y))+l.len;
 }

  return a;});
   line.d=  getSvgPathFromStroke(getStroke(line.points,line.opts));

 }


   lines.lines.map((line2,i)=>{

   if(!line2.done && line2.points){
     line2.points.map(a=>{ a.pressure = a.pressure<5 ? a.pressure+ .05 : 5;
       line2.done= a.pressure<5 ? false : true;
       return a;});
     line2.d=  getSvgPathFromStroke(getStroke(line2.points,line2.opts));

   }
   })
   //drawFrame(surface);
  // surface.requestAnimationFrame(drawFrame);
 }
}
 const hexToRgb=(hex)=> {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16)/256,
      g: parseInt(result[2], 16)/256,
      b: parseInt(result[3], 16)/256
    } : null;
  }
  const fub = (e)=> {

    color.current = e.color;
    size.current = e.size;
    brush.current = e.brush;
  }

const replay = ()=>{}
  const getStrokeC = (line,sub,n=0)=>{
if(line.points.length>1){
  const sz=line.opts.size;
  //
    line.d= getSvgPathFromStroke(getStroke(line.points,line.opts));
  line.opts.size+=5;
  const d1= getSvgPathFromStroke(getStroke(line.points,line.opts));
  line.opts.size+=5;
  const d2=getSvgPathFromStroke(getStroke(line.points,line.opts));
  line.opts.size=sz;
   // let firstPath = CanvasKit.Path.MakeFromSVGString(svg.match(/d="([^"]+)"/)[1]);
  let firstPath = CanvasKit.Path.MakeFromSVGString( line.d);
sub.drawPath(firstPath,paint);
  firstPath = CanvasKit.Path.MakeFromSVGString(d1);
  sub.drawPath(firstPath,paint);
  firstPath = CanvasKit.Path.MakeFromSVGString(d2);
  sub.drawPath(firstPath,paint);

  }   }
  const getStrokeC_ = (line,sub,n=0)=>{
const r = 100+n*2;

const ln =new paper.Path(line.points.map(a =>  [a.x,a.y]));
    const len0=ln.length;
ln.smooth({ type: 'continuous' });
//ln.simplify(10);

    /*    for(let i=6;
i >=0;
i--) {
          pa.stroke({precision:0.1,width: r+i*2, cap: CanvasKit.StrokeCap.Round});
          sub.drawPath(pa, paint        }
  */
    const len=ln.length;
    let prP= ln.getPointAt(0);

     let prT= ln.getNormalAt(0);
  sub.drawRRect(rrb, paint2);
    const tris=prP ? [prP.x-prT.x*r, prP.y-prT.y*r,
      prP.x,prP.y,
      prP.x+prT.x*r, prP.y+prT.y*r] : [];
    const cols=[CanvasKit.Color(0,0,0,0),  // Blue (fully opaque)
      CanvasKit.Color(256,0,0,1),CanvasKit.Color(0,0,0,0)
      ];
    const inds=[0,1,3,1,3,4,1,2,4,2,4,5];
    let p=line.points[0] ? line.points[0].pressure : 1;
    for(let i=1;
i < ln.segments.length;
i++){
  //  const po = ln.getPointAt(i*10);
      const po = ln.segments[i].point;
    //paint2.setColor(CanvasKit.Color4f(po.x/300,po.y/300,0,1));
      //sub.drawRect4f(i%300,Math.floor(i/300),i%300+1,Math.floor(i/300)*1+1, paint2);
           const tan = ln.getNormalAt(ln.getOffsetOf(po));

p=line.points[i] ? line.points[i].pressure/10 : 1;
 tris.push(po.x-tan.x*r,po.y-tan.y*r,
   po.x,po.y,
 po.x+tan.x*r,po.y+tan.y*r
);
      inds.push(3*i,3*i+1,3*i+4,3*i,3*i+3,3*i+4,
        3*i+1,3*i+2,3*i+5,3*i+1,3*i+4,3*i+5);

cols.push(CanvasKit.Color(p,p,p,1),  // Blue (fully opaque)
  CanvasKit.Color(p,p,p,1),CanvasKit.Color(p,p,p,1));

          prP=po;
          prT=tan;

   /*    */


       //  su.drawLine(po.x, po.y, po.x+tan.x*r, po.y+tan.y*r, paint);
          }

    const tan = ln.getNormalAt(len);
    const tan0 = ln.getTangentAt(len);
  const st =  ln.getPointAt(0);


    const ver = CanvasKit.MakeVertices(
      CanvasKit.VertexMode.Triangles,
      tris,
      null,
      cols,
      inds,true
    );
 sub.drawVertices(ver,CanvasKit.BlendMode.DstIn,paint1);

//console.log(tris);
     return len/10;
        }
        const handleDown = (e)=>{
//setType(e.width.toString() + ' '+e.pressure.toString());
      draw.current = true;
      draww.current = true;
      tm.current = Date.now();
      const totSt=lines.lines[0] ? lines.lines[0].start : Date.now();
          const opts = new Opts(size.current,brush.current);
       line = new Line(size.current,color.current,alpha,opts,totSt);
      //line = {points:[],d:'',start: Date.now() - totSt ,startT:Date.now(),opts:,,color:t,size:};
          surface.requestAnimationFrame(drawFrame);
          can1.requestAnimationFrame(drawFrame);
       }

        fetch(require('../static/128.png'))
          .then((response) => response.arrayBuffer())
          .then((buffer) => {
     // console.log(new Uint8Array(buffer));
            const image = CanvasKit.MakeImageFromEncoded(new Uint8Array(buffer));
          });
        const drawFrame_ = (ca)=>{
        // console.log('dddd');
          su1.clear(CanvasKit.TRANSPARENT);
          su1.drawRRect(rrb, paint1);
          can1.requestAnimationFrame(drawFrame_);
        }
        const drawFrame = (ca)=>{

if(line){tms++;
          if(!line.done) {
            //
if(tms%5==0){
            line.points.map(a => {
              a.life = a.life < 5 ? a.life + .05 : 5;
              line.done = a.life < 5 ? false : true;
              return a;
            });
            su1.drawRRect(rrb, paint2);
            const am = getStrokeC(line, su1, line.pressure);
            const im1 = can1.makeImageSnapshot([0, 0, xy[0], xy[1]]);

            const shader1 = im1.makeShaderOptions(
              CanvasKit.TileMode.Repeat,  // Tile the image in both X and Y directions
              CanvasKit.TileMode.Repeat,
              CanvasKit.FilterMode.Linear,
              CanvasKit.MipmapMode.Nearest,
              CanvasKit.Matrix.identity()
            );
            //  paint1.setShader(shader1);
            const uVectors = [0.1, 0.5, 0.9];
            const ff = am;
            const col = hexToRgb(color.current);
            const sh = shdF.makeShaderWithChildren(new Float32Array([ff, Date.now() - line.startT, col.r, col.g, col.b, xy[0], xy[1]]), [shader1], [
              1, 0, 0, 0,
              0, 1, 0, 0,
              0, 0, 1, 0,
              0, 0, 0, 1]);
            paint1.setShader(sh);

            im1.delete();
//su1.clear(CanvasKit.TRANSPARENT);
            su1.drawRRect(rrb, paint1);
          }else{

}
              can1.requestAnimationFrame(drawFrame);
              // surface.requestAnimationFrame(drawFrame);
              // su1.drawPath(firstPath, paint);
            }else{

            su1.drawRRect(rrb, paint2);
            const am = getStrokeC(line, su1, line.pressure);
            const im1 = can1.makeImageSnapshot([0, 0, xy[0], xy[1]]);

            const shader1 = im1.makeShaderOptions(
              CanvasKit.TileMode.Repeat,  // Tile the image in both X and Y directions
              CanvasKit.TileMode.Repeat,
              CanvasKit.FilterMode.Linear,
              CanvasKit.MipmapMode.Nearest,
              CanvasKit.Matrix.identity()
            );
            paint2.setShader(shader1);
            im1.delete();


          }

        }
  }
  const drawFrame_old = (ca)=>{

  //su.clear(CanvasKit.TRANSPARENT);

    //
    // canvas.drawVertices(ver,CanvasKit.BlendMode.Dst,paint1);
  //  su1.clear(CanvasKit.TRANSPARENT);
   su1.drawRRect(rrb, paint1);
    const llns =  lines.lines.map((line1,i)=>{
      if(line1 && !line1.done && line1.points){
        let nn=0;
        line1.points.map(a=>{ a.pressure = a.pressure<5 ? a.pressure+ .05 : 5;
       nn=a.pressure;   line1.done= a.pressure<5 ? false : true;
          return a;});
        //line1.d=  getSvgPathFromStroke(getStroke(line1.points,line1.opts));

        if(!line1.done)
        {
         // console.log(nn);
          let firstPath = CanvasKit.Path.MakeFromSVGString(line1.d);
          line1.points  && getStrokeC(line1.points,su1,nn);
          su1.drawPath(firstPath, paint);
        }
        if(line1.done && i==lines.lines.length-1)
        {

          let firstPath = CanvasKit.Path.MakeFromSVGString(line1.d);
          getStrokeC(line1.points,su1,5);
         // su.drawPath(firstPath, paint);
          const im1=  can1.makeImageSnapshot();

          const shader1 = im1.makeShaderOptions(
            CanvasKit.TileMode.Repeat,  // Tile the image in both X and Y directions
            CanvasKit.TileMode.Repeat,
            CanvasKit.FilterMode.Linear,
            CanvasKit.MipmapMode.Nearest,
            CanvasKit.Matrix.identity()
          );
          paint.setShader(shader1);
          su1.drawRRect(rrb, paint);
          const uVectors=[0.7,0.6,0.8];
          const sh = shdF.makeShader([],[
            1,0,0,0,
            0,1,0,0,
            0,0,1,0,
            0,0,0,1]);
          //console.log(su1);
          /*
          const sh_ = shdF.makeShaderWithChildren([uVectors],[shader1],[
            1,0,0,0,
            0,1,0,0,
            0,0,1,0,
            0,0,0,1]);
         // paint.setShader(sh);
*/
          paint1.setShader(sh);
          im1.delete();
          //const rrb = CanvasKit.LTRBRect(0, 0, 300, 500);
        //
          // canvas.drawVertices(ver,CanvasKit.BlendMode.Dst,paint1);
          console.log('dduu');
          //su.clear(CanvasKit.TRANSPARENT);
    // su.drawRRect(rrb, paint1);

        }


      }
      return line;
    })



//console.log(lines.lines);
    const lns=lines.lines.filter(a=>!a.done);
  //  console.log(lns.length,draww.current)
if  ((lns.length>0 && !draww.current) || (line && draww.current)) {
 // console.log('f')
  surface.requestAnimationFrame(drawFrame);
}

  }
 const delR = ()=>{
   lines.lines=[];
    setDia(false);
 }
  const handleUp = (e)=>{

    localStorage.getItem('size')
    draww.current = false;
const lns=lines.lines.filter(a=>!a.done);
  // surface.requestAnimationFrame(drawFrame);
 //  setTimeout(()=>setDraw(false),200) ;
   draw.current = false;
//console.log(getSvgPathFromStroke(getStroke(line.points)));

if(line!=undefined){
line.d= getSvgPathFromStroke(getStroke(line.points,line.opts));
line.end=Date.now() - line.startT;


    lines.lines.push(line.clone());
line =undefined;
  }
lines.lines.map((a,i)=>{
if(a.points) {

  const points = a.points.map(u => {

    return [Math.floor(u.x * 1000) / 1000, Math.floor(u.y * 1000) / 1000, Math.floor(u.pressure * 100) / 100, u.tm]

  })
}
//console.log(points,JSON.stringify({col:a.color,s:a.size,b:a.brush,p:points}).length);
})
//
  }
//  const filt=new CanvasKit.MaskFilter.MakeBlur(CanvasKit.BlurStyle.Normal,36.0,false);
//paint.setMaskFilter(filt);
  const filt1=new CanvasKit.ImageFilter.MakeBlur(15,15,CanvasKit.TileMode.Decal,null);
  paint.setImageFilter(filt1);
  const   shdS = CanvasKit.RuntimeEffect.Make(`
  uniform shader uImage;
    uniform shader uImage1;

vec4 main(vec2 pos) {
vec4 sum = uImage.eval(pos);
vec4 sum1 = uImage1.eval(pos);
return vec4(sum.rgb+sum1.rgb,1);
}
  `);
  const   shdF = CanvasKit.RuntimeEffect.Make(`

   vec3 hash( vec3 p ) // replace this by something better
{
p = vec3( dot(p,vec3(127.1,311.7, 74.7)),
dot(p,vec3(269.5,183.3,246.1)),
dot(p,vec3(113.5,271.9,124.6)));
return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}
float noise(vec3 p )
{
    vec3 i = floor( p );
    vec3 f = fract( p );
vec3 u = f*f*(3.0-2.0*f);
 return mix( mix( mix( dot( hash( i + vec3(0.0,0.0,0.0) ),
                           f - vec3(0.0,0.0,0.0) ),
  dot( hash( i + vec3(1.0,0.0,0.0) ), f - vec3(1.0,0.0,0.0) ), u.x),
  mix( dot( hash( i + vec3(0.0,1.0,0.0) ), f - vec3(0.0,1.0,0.0) ),
 dot( hash( i + vec3(1.0,1.0,0.0) ),
     f - vec3(1.0,1.0,0.0) ), u.x), u.y),
 mix( mix( dot( hash( i + vec3(0.0,0.0,1.0) ), f - vec3(0.0,0.0,1.0) ),
 dot( hash( i + vec3(1.0,0.0,1.0) ), f - vec3(1.0,0.0,1.0) ), u.x),
mix( dot( hash( i + vec3(0.0,1.0,1.0) ), f - vec3(0.0,1.0,1.0) ),
dot( hash( i + vec3(1.0,1.0,1.0) ),
    f - vec3(1.0,1.0,1.0) ), u.x), u.y), u.z );
}
float simpleNoise(vec2 uv,float freq) {
    return noise(vec3(freq * uv.xy, 1))*0.5 +0.5;
}
float hash(vec2 p) // Dave H
{
vec3 p3  = fract(vec3(p.xyx) * .1031);
    p3 += dot(p3, p3.yzx + 33.33);
    return fract((p3.x + p3.y) * p3.z);
}
float paper (vec2 U) { //https://www.shadertoy.com/view/NsfXWs
    float h = .005*(sin(.6*U.x+.1*U.y)+sin(.7*U.y-.1*U.x));
    for (float x = -1.; x<=1.;
x++)
    for (float y = -1.; y<=1.;
y++){
        h += .15*.125*hash(U+vec2(x,y));
    }
    return h;
}


uniform shader uImage;
uniform float ff;
uniform float tm;
uniform float r;
uniform float g;
uniform float b;
uniform float wi;
uniform float hi;

vec4 main(vec2 pos) {
//
vec4 sum = vec4(0.0);
float h = 5;
sum += uImage.eval( vec2( pos.x - 4.0 * h, pos.y ) ) * 0.051;
sum += uImage.eval( vec2( pos.x - 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x - 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x - 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x, pos.y ) ) * 0.1633;
sum += uImage.eval( vec2( pos.x + 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x + 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x + 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x + 4.0 * h, pos.y ) ) * 0.051;

sum = uImage.eval( vec2( pos.x, pos.y ));



float w =180+tm/50;
vec2 uv = pos / vec2(wi,hi);
  uv = vec2(uv.x*2-1,uv.y*2-1);
   uv.x *= wi / hi;

  int f1=int(floor(ff));
  vec3 ffb=vec3(0.0);
  float ret=sum.r;
 // float ret=smoothstep(.0,1.0,pow(sum.r,3));
//ret=sum.r<.7 ? 0 : cos((1-sum.r)*12);




float no=ret+simpleNoise(uv,60)*0.1;
 float largeNoise = simpleNoise(uv, 12);
no -= 0.1 * smoothstep(0.35, 1, largeNoise);

no=pow(clamp(no,0.0,1.0),2);

float wetNoiseFreq = 3.0;
    float wetNoiseStrength = 0.15;
    float wetNoise = simpleNoise(uv, wetNoiseFreq);
    float wetNoiseStep = smoothstep(0.25, 0.25 + wetNoiseStrength, wetNoise);
    float wetness = clamp(wetNoiseStep, 0.0, 1.0);



 float value;
    float valueSlim;float edge1;
    if (no < 0.0) {
        // no change in the center circle
        value = no;
        valueSlim = 1.0;
        edge1=1.0;
    } else {
        const float aaDelta = 0.006; // add a small delta to reduce aliasing
        const float edgeWet = 0.24;
       float edgeDry = 0.08;
         edge1 = mix(edgeDry, edgeWet, wetness) + aaDelta;
        value =     smoothstep(edgeDry, edge1, no);
        valueSlim = pow(1.0 - smoothstep(0.0, edge1, no), 0.15);
    }





value = value + 0.5 * valueSlim;

  float postNoise = simpleNoise(pos, .5);
    float ssStrength = .05;
    float postNoiseAmount = 0.5 - wetNoise;
    value = value + value *  postNoiseAmount * smoothstep(0.3, postNoise * ssStrength, value);

    float paper = paper(pos);
    float noiseCol = noise(vec3(2.0 * uv.x, 2.0 * uv.y, .4))*.5+.5;
    float gradient =0.5 * (uv.x + uv.y);
    //vec3 distCol = mix(vec3(r, g, b), vec3(noiseCol), vec3(r , g, b));
  vec3  distCol=vec3(r, g, b)*noiseCol+vec3(r*.1, g*.1, b)*(1-noiseCol);
    vec3 colors = mix(distCol, vec3(1.0,1.0,1.0), value*1.2 + paper * 1);
   // fragColor = vec4(colors,1) ;

//return vec4(colors,1) - paper * 0.3;

return vec4(vec3(sum.r),1);
//return vec4(vec3(pow(ret+max(0,no),0.7+min(0.5,tm/10000))),1);
//return  uImage.eval(pos);
 }`);
  /*
  const   shdF_ = CanvasKit.RuntimeEffect.Make(`
 vec3 hash( vec3 p ) // replace this by something better
{
p = vec3( dot(p,vec3(127.1,311.7, 74.7)),
dot(p,vec3(269.5,183.3,246.1)),
dot(p,vec3(113.5,271.9,124.6)));
return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}
float noise(vec3 p )
{
    vec3 i = floor( p );
    vec3 f = fract( p );
vec3 u = f*f*(3.0-2.0*f);
 return mix( mix( mix( dot( hash( i + vec3(0.0,0.0,0.0) ),
                           f - vec3(0.0,0.0,0.0) ),
  dot( hash( i + vec3(1.0,0.0,0.0) ), f - vec3(1.0,0.0,0.0) ), u.x),
  mix( dot( hash( i + vec3(0.0,1.0,0.0) ), f - vec3(0.0,1.0,0.0) ),
 dot( hash( i + vec3(1.0,1.0,0.0) ),
     f - vec3(1.0,1.0,0.0) ), u.x), u.y),
 mix( mix( dot( hash( i + vec3(0.0,0.0,1.0) ), f - vec3(0.0,0.0,1.0) ),
 dot( hash( i + vec3(1.0,0.0,1.0) ), f - vec3(1.0,0.0,1.0) ), u.x),
mix( dot( hash( i + vec3(0.0,1.0,1.0) ), f - vec3(0.0,1.0,1.0) ),
dot( hash( i + vec3(1.0,1.0,1.0) ),
    f - vec3(1.0,1.0,1.0) ), u.x), u.y), u.z );
}
float simpleNoise(vec2 uv,float freq) {
    return noise(vec3(freq * uv.xy, 1));
}


uniform float uVectors;
//uniform shader uImage;


vec4 main(vec2 pos) {
vec2 normalized = pos/vec2(1);
  vec4 sum = vec4(0.0);
float h = 5;
sum += uImage.eval( vec2( pos.x - 4.0 * h, pos.y ) ) * 0.051;
sum += uImage.eval( vec2( pos.x - 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x - 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x - 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x, pos.y ) ) * 0.1633;
sum += uImage.eval( vec2( pos.x + 1.0 * h, pos.y ) ) * 0.1531;
sum += uImage.eval( vec2( pos.x + 2.0 * h, pos.y ) ) * 0.12245;
sum += uImage.eval( vec2( pos.x + 3.0 * h, pos.y ) ) * 0.0918;
sum += uImage.eval( vec2( pos.x + 4.0 * h, pos.y ) ) * 0.051;
sum += uImage.eval(vec2( pos.x, pos.y - 4.0 * h) ) * 0.051;
sum += uImage.eval(vec2( pos.x, pos.y - 3.0 * h) ) * 0.0918;
sum += uImage.eval(vec2( pos.x, pos.y - 2.0 * h) ) * 0.12245;
sum += uImage.eval(vec2( pos.x, pos.y - 1.0 * h) ) * 0.1531;
sum += uImage.eval(vec2( pos.x, pos.y ) ) * 0.1633;
sum += uImage.eval(vec2( pos.x, pos.y + 1.0 * h) ) * 0.1531;
sum += uImage.eval(vec2( pos.x, pos.y + 2.0 * h) ) * 0.12245;
sum += uImage.eval(vec2( pos.x, pos.y + 3.0 * h) ) * 0.0918;
sum += uImage.eval(vec2( pos.x, pos.y + 4.0 * h) ) * 0.051;
sum=sum*0.5;
//vec4 color = blurH(uImage,10);


//vec4 color1 = vec4(uImage.eval(pos).rgba);

    //  float alpha = 1.0-(color.r*0.5 + color.g*0.2 + color.b*0.5);
 float smallNoise = simpleNoise(pos,0.1);
return  sum-smallNoise;
// return color1;
//return vec4(1-smallNoise*20,uImage.eval(pos).gba);
//(1-color.g)*color1.a
//return vec4(1,color1.g,color1.b,color1.a-(1-color.g));
 }`);
*/
 paper.setup([100,100]);
  const handleLeave = (e)=>{
    //setDraw(false);
    draww.current = false;
    draw.current = false;
  }


  useEffect(() => {



    if(size.current==null && page !== 'f'){


     can1.requestAnimationFrame(drawFrame);
     // surface.requestAnimationFrame(drawFrame);
      if(initDataUnsafe.user){
        store.getItem('color').then((b)=>{
          color.current=b;
        });
        store.getItem('size').then((b)=>{
          size.current=b;
        });
        store.getItem('brush').then((b)=>{
          brush.current=b;
        });
      }else {
        color.current = localStorage.getItem('color') ? localStorage.getItem('color') : color.current;
        brush.current = localStorage.getItem('brush')? localStorage.getItem('brush') : brush.current;
        size.current = localStorage.getItem('size')? localStorage.getItem('size') : size.current;
      }
    }

  //  if(page !== 'f')   {
   //   createConnection().get('sujet','filter=user,eq,11&filter=image,cs,mp4&include=id,image,thumb').then(a=> {
//setSujet(a.records);
     //  console.log( a.records.length)
    //  }
   //   );

  //  }
 // setPage('f');

  });
  return (
    <div style={{position:'absolute',top:0,left:0}}>
      {rec && (

        <video  controls autoPlay loop/>


      )}


            <div ref={myCan} className="h-screen  lock w-screen"
                 onPointerMove={ptMove}

              // onPointerLeave={e => handleLeave(e)}
                 onPointerDown={e => handleDown(e)}
                 onPointerUp={e => handleUp(e)}

            >
              {type}
            </div>




      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >

                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5"/>
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                         Esketchos
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {!draw.current &&
        (
          <div className="fixed bottom-0 right-0 ">

            {lines.length>0 && (
              <>
            <button
              type="button"
              className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => del()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3zM7 6h10v13H7zm2 2v9h2V8zm4 0v9h2V8z"/>
              </svg>
            </button>


              <button
                type="button"
                className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => replay()}
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="m10 16.5l6-4.5l-6-4.5M22 12c0-5.54-4.46-10-10-10c-1.17 0-2.3.19-3.38.56l.7 1.94c.85-.34 1.74-.53 2.68-.53c4.41 0 8.03 3.62 8.03 8.03s-3.62 8.03-8.03 8.03S3.97 16.41 3.97 12c0-.94.19-1.88.53-2.72l-1.94-.66C2.19 9.7 2 10.83 2 12c0 5.54 4.46 10 10 10s10-4.46 10-10M5.47 3.97c.85 0 1.53.71 1.53 1.5C7 6.32 6.32 7 5.47 7c-.79 0-1.5-.68-1.5-1.53c0-.79.71-1.5 1.5-1.5"/>
                </svg>
              </button>
              <button
                type="button"
                className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => undo()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M12.5 8c-2.65 0-5.05 1-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8"/>
                </svg>
              </button>
              </>
  )}
              <button
                type="button"
                className="rounded-md m-2 text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setOpenP(true)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"/>
                </svg>
              </button>
              <button
                type="button"
                className="rounded-md text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setOpen(true)}
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="#cccccc"
                        d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M6 7h12v2H6zm0 4h12v2H6zm0 4h12v2H6z"/>
                </svg>
              </button>
          </div>
        )
      }
      <DialogP p={openP} fu={setOpenP} size={size.current} color={color.current} brush={brush.current}  fub={fub}/>
<Dialogo p={dia} fu={setDia} del={delR}/>
    </div>
  );
}
export default EsketC;
