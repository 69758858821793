import React, {useState, useEffect, useRef} from "react";

import Can from "./can";
import anim from "./anim";
import SujetP from "./thumbs/sujetP";

import EsketC from "./esketC";


const Canv = (props)=> {
  const [pkit,setPkit]=useState(null);
  const [count,setCount]=useState(0);
  const [ready,setReady]=useState(null);
  /*
const getA=()=>wrapTo;

  const calcWrapTo = (time)=>
    350 + 150 * Math.sin(time / 2000);
  const [wrapTo, setWrapTo] = useState(calcWrapTo(performance.now()));
  anim((time) => setWrapTo(calcWrapTo(time)));
*/
 useEffect(() => {
   const CanvasKitInit = require('../js/canvaskit')
   console.log('ssssssssssss');

   CanvasKitInit().then((CanvasKit) => {

setReady(CanvasKit);
if(!ready){
  const rootElement = document.getElementById("ttid");
  //const ;

  const htmlCanvasElement = document.createElement("canvas");
       htmlCanvasElement.width = rootElement.clientWidth;
       htmlCanvasElement.height = rootElement.clientHeight;
       htmlCanvasElement.id='foo';
  const htmlCanvasElement1 = document.createElement("canvas");
  const w= rootElement.clientWidth;
  const h= rootElement.clientHeight;
  htmlCanvasElement1.width = rootElement.clientWidth;
  htmlCanvasElement1.height = rootElement.clientHeight;
  htmlCanvasElement1.id='foo1';

  rootElement.appendChild(htmlCanvasElement1);
 rootElement.appendChild(htmlCanvasElement);

/*
       render(

           <EsketC prop={CanvasKit} />


         , htmlCanvasElement);
*/
     }



     });

 return () => {
       console.log('og');
     //cancelAnimationFrame(requestRef.current);
     }

/* //  */
 //   rafId.current = requestAnimationFrame(drawFrame)
}, []);


  return (
    <>
    {
      ready ? (
 <EsketC prop={ready} />


      ):(
        <span>notready</span>

      )


    }

</>


  );
}
export default Canv;
