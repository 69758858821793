import React, { Fragment, useRef, useState } from 'react'
import {Dialog, Field, Fieldset, Input, Label, Legend, Select, Textarea, Transition} from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
import TelegramLoginButton from "react-telegram-login";


export  function DialogS (p) {
  const [ready, setReady] = useState(false)
  const [nm, setNm] = useState('')
  const [descr, setDescr] = useState('')
  const [story, setStory] = useState(0)
  const cancelButtonRef = useRef(null)

  return (


    <Transition.Root show={p.p} as={Fragment}>
      <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={p.fu}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                    </div>
                    <div className="mt-3 p-2 text-center sm:ml-4 sm:mt-0 sm:text-left flex-grow bg-gray-100">

                      <div className="mt-2">
                        <Fieldset className="space-y-4">
                          <Legend className="text-lg font-bold">Save your work</Legend>
                          {p.user && p.user.id!=[] ? '':(<small>You have to login</small>)}
                          <Field>

                            <Input className="mt-1 block" name="name" onChange={(a) => setNm(a.target.value)}  placeholder="*name"/>
                          </Field>

                          {p.user && p.user.id!=[]  ? (
                          <Field>
                            <Label className="block">Select story</Label>
                            <Select className=" block" name="story" onChange={a=>setStory(a.target.value)}>
                              {p.story && p.story.map((a, i) => (

                                <option key={i} defaultValue={p.story[p.story.length - 1].id} value={a.id}>{a.name}</option>

                              ))}
                              <option   value={0}>New story</option>
                            </Select>
                          </Field>
                          ) : (

                            <div className="App">
                              Login
                              <TelegramLoginButton  buttonSize="small"  dataOnauth={(a) => p.login(a)} botName="EsketchosBot"/>

                              {process.env.REACT_APP_TEST && (
                                <button onClick={() => p.login({
                                  id: 6429696514,
                                  username: 'sdfsdf',
                                  photo_url: 'https://t.me/i/userpic/320/RLQ8xT8-Qrh-gzbq_NVj4SbwTuz-vgboie7j4sAdZ7r2StPbgqnPQTTBdLOi6STt.jpg'
                                })}>sss
                                </button>
                              )}


                            </div>

                          )

                          }
                          <Field>
                            <Label className="block">Description</Label>
                            <Textarea className="mt-1 block" name="notes"
                                      onChange={a=>setDescr(a.target.value)}/>

                          </Field>
                        </Fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                  <button
                    type="button" disabled={nm.length < 4 && !p.user}
                    className={(nm.length < 4 && !p.user ? 'bg-gray-400 ' : 'bg-red-500 text-white') +" inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm  sm:ml-3 sm:w-auto"}
                    onClick={() => p.act(story,nm,descr)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => p.fu(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export  function Dialogo (p) {
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)

  return (


    <Transition.Root show={p.p} as={Fragment}>
      <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={p.fu}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">

                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete drawing. This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => p.del()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => p.fu(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export  function DialogP (p) {
  const st= useCloudStorage()
  const [open, setOpen] = useState(false)
  const [color, setColor] = useState(p.color)
  const [size, setSize] = useState(p.size)
  const [brush, setBrush] = useState(p.brush)
  const sizes = [4, 6, 8, 12, 20, 28, 40, 60];
  const colors1 =  ['#000000', '#ff5252', '#ffbc00', '#00c853', '#00b0ff', '#d500f9', '#8d6e63', '#fafafa', '#a52714', '#ee8100', '#558b2f', '#01579b', '#8e24aa', '#4e342e', '#90a4ae', '#ff4081', '#ff6e40', '#aeea00', '#304ffe', '#7c4dff', '#1de9b6', '#cfd8dc', '#f8bbd0', '#ffccbc', '#f0f4c3', '#9fa8da',
    '#d1c4e9', '#b2dfdb'];
 const cancelButtonRef = useRef(null)
  const [initDataUnsafe, initData] = useInitData();
const stsetItem=(a,v)=>{

    if(initDataUnsafe.user){ st.setItem(a,v);} else {localStorage.setItem(a,v)}

  }

const tapCol = (u)=>{
   p.fub({color:u,size:size,brush:brush});
   setColor(u)
  if(initDataUnsafe.user){ st.setItem('color',u);} else {localStorage.setItem('color',u)}
 }
  return (
    <>
      <button
        type="button"
        className="rounded-md m-2 text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setOpen(true)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
          <path fill="currentColor"
                d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"/>
        </svg>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={p.fu}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start flex-col">


                      <div style={{width: '100%'}} className="flex justify-center align-middle">


                        <div className={brush == 'pen' ? 'rounded-full bg-gray-200 m-2 w-12 h-12' : 'm-2 w-12 h-12'}>
                          <svg onClick={() => {
                            setBrush('pen');
                            stsetItem('brush', 'pen');
                            p.fub({color: color, size: size, brush: 'pen'})
                          }} fill="none" fillRule="evenodd" height="36px" stroke="none"
                               version="1.1" viewBox="0 0 24 24" width="36px" xmlns="http://www.w3.org/2000/svg">
                            <polygon fill={brush == 'pen' ? '#424242' : '#eee'}
                                     points="3 17.25 3 21 6.74 21 14.28 13.47 10.53 9.72"></polygon>
                            <path fill={brush == 'pen' ? color : '#626262'}
                                  d="M18.37,3.3 L20.71,5.63 C21.1,6.02 21.11,6.66 20.72,7.05 L15.35,12.41 L11.59,8.65 L14.12,6.12 L13.39,5.39 L7.73,11.05 L6.33,9.65 L12.7,3.29 C13.09,2.9 13.74,2.91 14.12,3.3 L15.54,4.71 L16.96,3.3 C17.34,2.91 17.98,2.91 18.37,3.3 L18.37,3.3 Z"></path>
                            <path
                              fill={brush == 'pen' ? color : '#626262'} className="ink-cap-border"
                              d="M17.7,4L20,6.3L15.4,11L13,8.6l1.8-1.8l0.7-0.7l-0.7-0.7l-0.2-0.2l0.2,0.2l0.7,0.7l0.7-0.7L17.7,4 M13.4,3 c-0.3,0-0.5,0.1-0.7,0.3L6.3,9.6l1.4,1.4l5.7-5.7l0.7,0.7l-2.5,2.5l3.8,3.8L20.7,7c0.4-0.4,0.4-1,0-1.4l-2.3-2.3 C18.2,3.1,17.9,3,17.7,3S17.2,3.1,17,3.3l-1.4,1.4l-1.4-1.4C13.9,3.1,13.7,3,13.4,3L13.4,3z"></path>
                          </svg>
                        </div>
                        <div className={brush == 'flo' ? 'rounded-full m-2 w-12 h-12  bg-gray-200' : 'm-2 w-12 h-12'}>
                          <svg onClick={() => {
                            stsetItem('brush', 'flo');
                            setBrush('flo');
                            p.fub({color: color, size: size, brush: 'flo'})
                          }} fill="none" fillRule="evenodd" height="36px" stroke="none"
                               version="1.1" viewBox="0 0 24 24" width="36px" xmlns="http://www.w3.org/2000/svg">
                            <path fill={brush == 'flo' ? '#424242' : '#eee'}
                                  d="M9.7,9.49 L13.99,13.79 L7.45,20.33 L6.73,19.61 L5.3,21.04 C4.51,21.83 3.23,21.83 2.44,21.04 C1.65,20.25 1.65,18.96 2.44,18.18 L3.87,16.75 L3.15,16.03 L9.7,9.49 L9.7,9.49 Z"></path>
                            <path fill={brush == 'flo' ? color : '#626262'}
                                  d="M21.26,3.69 L19.8,2.22 C19.02,1.44 17.75,1.44 16.97,2.22 L10.76,8.43 L15.05,12.73 L21.26,6.52 C22.05,5.74 22.05,4.47 21.26,3.69 L21.26,3.69 Z"></path>
                            <path fill={brush == 'flo' ? color : '#626262'}
                                  d="M18.4,2.6c0.3,0,0.5,0.1,0.7,0.3l1.5,1.5c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7l-5.5,5.5l-2.9-2.9 l5.5-5.5C17.9,2.7,18.1,2.6,18.4,2.6z"></path>
                          </svg>
                        </div>
                        <div className='m-2 w-12 h-12'
                             style={brush == 'aqua' ? {borderColor: color} : {borderColor: 'none'}}>
                          <svg onClick={() => {
                            setBrush('aqua');
                            stsetItem('brush', 'aqua');
                            p.fub({color: color, size: size, brush: 'aqua'})
                          }} fill="none" fillRule="evenodd" height="36px" stroke="none"
                               version="1.1" viewBox="0 0 24 24" width="36px" xmlns="http://www.w3.org/2000/svg">
                            <path fill={brush == 'aqua' ? '#424242' : '#eee'}
                                  d="M10.22,9.49 L4.31,15.49 C3.54,16.29 3.61,17.54 4.39,18.34 L0.77,22 L6.45,22 L7.19,21.25 C7.97,22.06 9.14,22.11 9.92,21.3 L15.88,15.25 L10.22,9.49 L10.22,9.49 Z"></path>
                            <path fill={brush == 'aqua' ? color : '#626262'}
                                  d="M22.68,5.49 L19.86,2.62 C19.08,1.82 17.79,1.78 17.02,2.58 L11.27,8.43 L16.93,14.18 L22.62,8.4 C23.39,7.59 23.45,6.29 22.68,5.49 L22.68,5.49 Z"></path>
                            <path fill={brush == 'aqua' ? color : '#626262'}
                                  d="M18.4,3c0.3,0,0.5,0.1,0.7,0.3L22,6.2c0.4,0.4,0.4,1.1-0.1,1.5l-5,5.1l-4.3-4.3l5.1-5.2 C17.9,3.1,18.1,3,18.4,3z"></path>
                          </svg>
                        </div>

                      </div>

                      <div
                        className="mx-auto flex h-12  flex-shrink-0 items-center justify-center">
                        {sizes.map((u, i) => (
                          <div key={i} onClick={() => {
                            setSize(u);
                            stsetItem('size', u);
                            p.fub({color: color, size: u, brush: brush})
                          }} className={'rounded-full mx-2 ' + (u == size ? 'bg-black' : 'bg-neutral-100')}
                               style={{width: u + 4, height: u + 4}}></div>
                        ))}
                      </div>
                      <div className="mt-3 text-center flex mx-auto sm:mt-0 sm:text-left">

                        <div className="mt-2 grid grid-cols-7">
                          {colors1.map((u, i) => (


                            <button onClick={color => tapCol(u)} key={i} className="rounded-full m-1 w-8 h-8 shadow-lg"
                                    style={{backgroundColor: u}}></button>

                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
