import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import Date from "moment";
import {paper} from "paper/dist/paper-full.min";
import {Dialogo, DialogP, DialogS} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
import {useDom} from "../context/dom";

//import {Store} from "../services/store";\
import TelegramLoginButton from 'react-telegram-login';
import {Link} from "react-router-dom";
import MenuB from "./menus/menuB";


export default function Esket(props) {
  const dom=useDom();
  const getStories = (r) => {
   // !user && setUser(r);
    if (story && story.length == 0) {
      createConnection().get('story', 'filter=user,eq,' + r.id + '&join=sujet&exclude=sujet.code').then(c => {
          c.records ? setStory(c.records) : setStory(null);

        }
      );
    }

  }
  const teleLogin = async (a) => {
    setUser(a);
    const response = await fetch("https://buben-sha.herokuapp.com/api/records/users?exclude=password&filter=tgid,eq," + a.id);
    await response.json().then(t => {
        if (t.records.length >= 1) {
          const r = t.records[0];
          setUser(r);
          getStories(r);

          r.photo_url = a.photo_url;
          r.username = a.username;
          localStorage.setItem('userId', r.id);
          document.cookie = 'userId=' + r.id + '; path=/; Expires=Thu, 01 Jan 2026 00:00:01 GMT;';
          // dom.setUser(a.records[0]);


        }

      }
    )

  }
  const [user, setUser] = useState(props.pro);
  const [story, setStory] = useState([]);
  const [sujets, setSujets] = useState([]);
  const [page, setPage] = useState(null);

  const store = Number(window.Telegram.WebApp.version) >= 8 ? window.Telegram.WebApp.CloudStorage : null;
  // const store = Store();
  const [rec, setRec] = useState(false);
  const [open, setOpen] = useState(false);
let openP = false;

  const brush= useRef('pen');
  const color =useRef(null);
  const size =useRef(2);
  const [alpha, setAlpha] = useState(.8);

  const [draw, setDraw] = useState(false);
  const [tm, setTm] = useState(Date.now());
  const [lines, setLines] = useState([]);
  // const [line, setLine] = useState({points: [], d: ''});
  const [press, setPress] = useState('');
  const [sujet, setSujet] = useState({id: 0});
  const [inte, setInte] = useState(0);

  const myCan = useRef(null);
  const draww = useRef(false);
  const pp = useRef(false);
  let temp = 0;
  let se = 0;
  const ww = window.innerWidth;
  const hh = window.innerHeight;
  const mat = [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1];
  let shader1;
  let bgImage;
  const surf = useRef(null);

  const line = useRef(null);

  let currentLine = 0;


    if (store) {
      store.getItem('color', (b) => {
        color.current = new paper.Color(b);
      });
      store.getItem('size', (b) => {
       size.current=b;
      });
      store.getItem('brush', (b) => {
        brush.current=b;
      });
  }

  const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  const repl = () => {
    temp += 3;

    lines.map((a, i) => {
      if (i === currentLine) {


        a.d = getSvgPathFromStroke(getStroke(a.points.filter(t => t.tm < temp), a.opts));
        // console.log(a.d);
        setPress(temp);
        if (a.end < temp) {
          i == lines.length - 1 && clearInterval(se);

          setRec(true);
          currentLine++;
          temp = 0;

//console.log(lines);
        }
      }

    })
    setLines(lines);
  }
  const replay = () => {

    currentLine = 0;
    setLines(lines.map(a => a.d = ''));
    setTm(Date.now());
    se = setInterval(repl, 2);
    //setPress(press+1);
  }

  const undo = () => {
    lines.pop();
    setLines(lines);
    setPress(press + 1);
  }

  const ptMove = (e) => {

    if (draww.current) {
      temp++;
      //console.log(line.current);
      // setPress(press + 1);
      const co = e.width == 0 ? e.pointerType === 'pen' ? 10 : 20 : e.pointerType === 'pen' ? 10 : 1;
      if (line.current) {

        line.current.points.push({
          x: e.clientX,
          y: e.clientY,
          pressure: brush == 'aqua' ? 0.01 : e.pressure * co,
          pre: e.pressure * co,
          ttm: 0,
          tm: Date.now() - line.current.startT
        });
        line.current.points.map(a => {
          a.pressure = a.pressure < 5 ? a.pressure + .05 : 5;
          line.done = a.pressure < 5 ? false : true;
          return a;
        });
        line.current.dr = getSvgPathFromStroke(getStroke(line.current.points, line.current.opts));

        //  setLine(line);
      }
      lines.map((line, i) => {
        if (!line.done) {
          line.points.map(a => {
            a.pressure = a.pressure < 5 ? a.pressure + .05 : 5;
            line.done = a.pressure < 5 ? false : true;
            return a;
          });
          line.dr = getSvgPathFromStroke(getStroke(line.points, line.opts));

        }
      })


    }
  }


  const getStrokeC = (pts) => {


    const ln = new paper.Path(pts.map(a => [a.x, a.y]));

    ln.smooth({type: 'continuous'});
    ln.simplify(10);
    ln.segments.map(f => f.point.x);
    return ln.exportSVG({asString: true}).match(/d="([^"]+)"/)[1];
  }
  const handleDown = (e) => {
    // setPress(e.pointerType);
    //setDraw(true);
    draww.current = true;
   //


    const totSt = lines[0] ? lines[0].start : Date.now();
    const opts = new Opts(size.current, brush);

    line.current = {
      points: [],
      d: '',
      start: Date.now() - totSt,
      startT: Date.now(),
      opts: opts,
      alpha: alpha,
      color: color,
      size: size.current,
      brush: brush
    };

  //  setTm(Date.now());
   // surface.requestAnimationFrame(drawFrame);

    surface.requestAnimationFrame(drawFrame);
  }

  const drawFrame = (tt) => {

    let done = 1;
    if (line.current && !line.current.done && line.current.points.length > 0) {

      line.current.points.map(a => {
        a.pressure = a.pressure < 5 ? a.pressure + .05 : 5;
      line.current.done = a.pressure < 5 ? false : true;
        return a;
      });
     // line.current.d = getSvgPathFromStroke(getStroke(line.current.points, line.current.opts));
      //   (line.current.dr &&  line.current.dr!='') &&    console.log(line.current.dr);

      if (ck) {

        const firstPath = ck.Path.MakeFromSVGString(line.current.dr);

        tt.drawRRect(rrb, paint0);
        tt.drawPath(firstPath, paint);

        const im2 = surface.makeImageSnapshot([0, 0, ww, hh]);
        shader1 = im2.makeShaderOptions(
          ck.TileMode.Repeat,  // Tile the image in both X and Y directions
          ck.TileMode.Repeat,
          ck.FilterMode.Linear,
          ck.MipmapMode.Nearest,
          ck.Matrix.identity()
        );


        im2.delete();
        //console.log(paperB,bgImage,shader1);
        su.clear();

        const shh = shdS.makeShaderWithChildren(new Float32Array([color.current.red,color.current.green,color.current.blue,alpha]), [shader1, bgImage], mat);

        paintB.setShader(shh);
        //paintC.setShader(shader1);
     su.drawRRect(rrb, paintB)
       // su.drawRRect(rrb, paintC)
      //  console.log(line.current.done);
    /*    */
        //  cka.su.drawPath(firstPath, cka.paint);
        //  cka.su.drawPath(firstPath, cka.paint);
        //  cka.su.drawShadow(firstPath, [0,0,0], [0,0,10],4,cka.ck.Color(0, 0, 0, 1), cka.ck.Color(200, 200, 200, 1), 1);
      }
    }
    const llns = lines.map((line, i) => {
      if (!line.done) {
        line.points.map(a => {
          a.pressure = a.pressure < 5 ? a.pressure + .05 : 5;
          line.done = a.pressure < 5 ? false : true;
          return a;
        });
        line.d = getSvgPathFromStroke(getStroke(line.points, line.opts));
        line.dv = getStrokeC(line.points);

      } else {
        done = 0;
      }
      return line;
    })

    const lns = lines.filter(a => !a.done);
    // setLines(llns);
    // cka.surface && cka.surface.drawOnce(()=>{})
    //
    if(!line.current){
      su.drawRRect(rrb, paintB)
  //   su.drawRRect(rrb, paintC)

      const im2 = surface.makeImageSnapshot([0, 0, ww, hh]);
      bgImage = im2.makeShaderOptions(
        ck.TileMode.Repeat,  // Tile the image in both X and Y directions
        ck.TileMode.Repeat,
        ck.FilterMode.Linear,
        ck.MipmapMode.Nearest,
        ck.Matrix.identity()
      );
      im2.delete();

      const shh = shdS.makeShaderWithChildren(new Float32Array([color.current.red,color.current.green,color.current.blue,alpha]), [shader1, bgImage], mat);

      paintB.setShader(shh);


    }



 (line.current && !line.current.done) && surface.requestAnimationFrame(drawFrame);

  }

  const handleUp = (e) => {



/**/
      //localStorage.getItem('size')

      line.current.done = true;

      const lns = lines.filter(a => !a.done);



      line.current.d = getSvgPathFromStroke(getStroke(line.current.points, line.current.opts));
      line.current.end = Date.now() - line.current.startT;
      line.current.endT = Date.now();
      lines.push(line.current);


      lines.map((a, i) => {
        const points = a.points.map(u => {

          return [Math.floor(u.x * 1000) / 1000, Math.floor(u.y * 1000) / 1000, Math.floor(u.pressure * 100) / 100, u.tm]

        })
//console.log(points,JSON.stringify({col:a.color,s:a.size,b:a.brush,p:points}).length);
      })
      line.current = null;



    draww.current = false;
  }
  const handleLeave = (e) => {
    setDraw(false);
    draww.current = false;
  }
console.log('ddd')

  const ck = props.prop;
  const filt1 = new ck.ImageFilter.MakeBlur(5, 5, ck.TileMode.Decal, null);
  const filt0 = new ck.MaskFilter.MakeBlur(ck.BlurStyle.Normal, 15,false);
  const filt2 = new ck.ImageFilter.MakeErode(1, 1, null);
  const filt3 = new ck.ImageFilter.MakeDropShadow(0, 0, 25, 25, ck.Color(0, 0, 0, 1), null);
  const surface = ck.MakeCanvasSurface('foo');
  const su = surface.getCanvas();
  const paint = new ck.Paint();
  paint.setColor(ck.Color(255,255,255,1)); // Black color
  paint.setAntiAlias(true);
  paint.setStyle(ck.PaintStyle.Fill);
  const paint0 = new ck.Paint();
  paint0.setColor(ck.Color(0,0,0,1)); // Black color
  paint0.setAntiAlias(true);
  paint0.setStyle(ck.PaintStyle.Fill);
  const paintB = new ck.Paint();
  //paintB.setColor(ck.Color(0, 0, 0, 1)); // Black color
  paintB.setAntiAlias(true);
  paintB.setStyle(ck.PaintStyle.Fill);
  const paintC = new ck.Paint();
  paintC.setAntiAlias(true);
  paintC.setStyle(ck.PaintStyle.Fill);
  const rrb = ck.LTRBRect(0, 0,ww, hh);
  // paintB.setImageFilter(filt3);
  paint.setMaskFilter(filt0);

  const im2 = surface.makeImageSnapshot([0, 0, ww, hh]);
  bgImage = im2.makeShaderOptions(
    ck.TileMode.Repeat,  // Tile the image in both X and Y directions
    ck.TileMode.Repeat,
    ck.FilterMode.Linear,
    ck.MipmapMode.Nearest,
    ck.Matrix.identity()
  );
  im2.delete();
  paintB.setShader(bgImage);
  const shdS = ck.RuntimeEffect.Make(`
  uniform shader uImage;
    uniform shader uImage1;
uniform float r;
uniform float g;
uniform float b;
uniform float a;
vec4 main(vec2 pos) {
vec4 sum = uImage.eval(pos);
vec4 sum1 = uImage1.eval(pos);
vec4 layer1 =vec4(r,g,b,sum.r*a)*sum.r*a;
vec4 layer2 = sum1*(1-sum.r*a);
//return sum;
return layer1+layer2;
//return vec4(vec3(r*(sum.r),g*(sum.r),b*(sum.r)),sum.a) + sum1;//sum.a+sum1.a
}
  `);

  //


//  const filt=new CanvasKit.MaskFilter.MakeBlur(CanvasKit.BlurStyle.Normal,36.0,false);
//
  //setOpenP(false);
  const fub = (e) => {

color.current=e.color ? new paper.Color(e.color) : color.current;
    size.current=e.size ? e.size : size.current;
  }
  useEffect(() => {
    paper.setup([100, 100]);
  //  setOpenP(true);
   color.current=new paper.Color('#ff0000');
 //setColor(cl);
  //  props.pro && setUser(props.pro);
  //  (props.pro && story && story.length == 0) && getStories(props.pro);


  }, []);





//surface.drawOnce(()=>{});


  function actStory(st) {
    story.map(p => p.active = false);
    st.active = true;
    setSujets(st.sujet);
    setSujet({id: 0});
  }

  function actSujet(st) {
    setSujet(st);
  }

  return (
    <>
      <div id="saad" className="absolute top-0 left-0" style={{width: window.innerWidth, height: window.innerHeight}}>

        <div ref={myCan} className="h-screen  lock w-screen"
             onPointerMove={ptMove} onPointerDown={e => handleDown(e)}
             onPointerUp={e => handleUp(e)}

        >

        </div>

        <Transition.Root show={open} as={Fragment}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >

                    <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                          <button
                            type="button"
                            className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5"/>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Esketchos
                          </Dialog.Title>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">


                          {user && user.id != [] ? (

                            <div>
                              {user.first_name && user.first_name}
                              {user.photo_url && (
                                <>
                                  <div className="w-8 rounded-full overflow-hidden"><img src={user.photo_url}/></div>
                                  <small>@{user.username}</small>

                                  <div><h5>Your stories</h5>{story && story.map((st, i) => (
                                    <span onClick={() => actStory(st)} key={i}
                                          className={"cursor-pointer inline-flex items-center rounded-md " + (st.active ? 'bg-amber-100' : 'bg-gray-50') + "  px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-1"}>{st.name}</span>))}
                                  </div>
                                  <div className="mt-2"> {sujets && sujets.map((st, i) => (
                                    <span onClick={() => actSujet(st)} key={i}
                                          className={"cursor-pointer inline-flex items-center rounded-md " + (st.id === sujet.id ? 'bg-amber-500 text-white' : 'bg-gray-50') + "  px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-1"}>{st.name}</span>))}
                                  </div>
                                  {sujet && sujet.name ? (

                                    <div>
                                      <Link to={"/sujetP/" + sujet.id}>
                                        {sujet.thumb ? (
                                          <img src={sujet.thumb}/>

                                        ) : (

                                          <span>{sujet.name}</span>

                                        )}

                                      </Link>
                                    </div>

                                  ) : ''}
                                </>
                              )}
                            </div>


                          ) : (

                            <div className="App">
                              <TelegramLoginButton buttonSize="small" dataOnauth={(a) => teleLogin(a)}
                                                   botName="EsketchosBot"/>
                              {process.env.REACT_APP_TEST && (
                                <button onClick={() => teleLogin({
                                  id: 6429696514,
                                  username: 'sdfsdf',
                                  photo_url: 'https://t.me/i/userpic/320/RLQ8xT8-Qrh-gzbq_NVj4SbwTuz-vgboie7j4sAdZ7r2StPbgqnPQTTBdLOi6STt.jpg'
                                })}>sss</button>
                              )}                          </div>


                          )}

                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {!draww.current &&
          (
            <div className="fixed bottom-0 right-0 ">

              {lines.length > 0 && (
                <>
                  <button
                    type="button"
                    className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setDias(true)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round"
                            d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => del()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3zM7 6h10v13H7zm2 2v9h2V8zm4 0v9h2V8z"/>
                    </svg>
                  </button>


                  <button
                    type="button"
                    className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => replay()}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="m10 16.5l6-4.5l-6-4.5M22 12c0-5.54-4.46-10-10-10c-1.17 0-2.3.19-3.38.56l.7 1.94c.85-.34 1.74-.53 2.68-.53c4.41 0 8.03 3.62 8.03 8.03s-3.62 8.03-8.03 8.03S3.97 16.41 3.97 12c0-.94.19-1.88.53-2.72l-1.94-.66C2.19 9.7 2 10.83 2 12c0 5.54 4.46 10 10 10s10-4.46 10-10M5.47 3.97c.85 0 1.53.71 1.53 1.5C7 6.32 6.32 7 5.47 7c-.79 0-1.5-.68-1.5-1.53c0-.79.71-1.5 1.5-1.5"/>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => undo()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="M12.5 8c-2.65 0-5.05 1-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8"/>
                    </svg>
                  </button>
                </>
              )}


            </div>
          )
        }


      </div>
      <DialogP pr={openP} fu={()=>{}} size={size.current} color={color.current} brush={props.brush} fub={fub}/>
    </>
  );
}
