import React, {useState, useEffect, useContext} from "react";


export default function User(props) {

  const [user, setUser] = useState(null);

  const [role, setRole] = useState(null);




  useEffect(() => {


  }, []);

  if (!userf) {
    return "loading...";
  }

  return (
    <div>
      {userf && userf.rights}
    </div>
  );
}
