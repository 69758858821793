export class Line {
  id;
points;
d;
done;
alpha;
color;
size;
startT;
end;
opts;
start;
life;

  constructor(size, color,alpha,opts,start0,id) {
    this.points = [];
    this.d='';
    this.dv='';
    this.id=id;
    this.startT=Date.now();
    this.life=0;
    this.start=Date.now() - start0;
    this.opts=opts;
    this.color=color;
  }
  clone =()=>{
const ll= new Line(this.size,this.color,this.alpha,this.start,this.id);
ll.done=this.done;
ll.startT= this.startT;
ll.start=this.start;
ll.d=this.d;
ll.end=this.end;
ll.points=this.points;
ll.life=this.life;
ll.opts=this.opts;
return ll;

  }
}

export class LinesC {

  lines;

  constructor() {
    this.lines = [];

  }
  getSvgPathFromStroke = (stroke)=> {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
}
