export default class Opts {
  size;
  end;
  start;
  smoothing;
  thinning;
  streamline;
  easing;
  simulatePressure;

  constructor(size, type) {
    this.size = size;

    if (type === 'aqua') {
      this.end = {taper: 0, cap: true};
      this.start = {taper: 0, cap: true};
      this.thinning = 0.5;
      this.smoothing = 0.1;
      this.easing = (t) => t;
      this.size = size * 1;
      this.simulatePressure = false;
    }  else if (type === 'pen') {
      /*
      this.end = {taper: 8, cap: false};
      this.start = {taper: 8, cap: false};
      this.thinning = 0.07;
      this.smoothing = 0.1;
      this.streamline = 0.1;
      this.easing = (t: number) => t*t*t*t;
      this.simulatePressure = false;


       */
      this.end = {taper: 8, cap: false};
      this.start = {taper: 8, cap: false};
      this.thinning = 0.07;
      this.smoothing = 0.1;
      this.streamline = 0.1;
      this.easing = (t) => t*t*t*t;
      this.simulatePressure = false;
    }else if (type === 'flo') {
      this.end = {taper: 0, cap: true};
      this.start = {taper: 0, cap: true};
      this.thinning = 0.1;
      this.smoothing = 0;
      this.easing = (t) => t;
      this.simulatePressure = false;
    } else {
      this.end = {taper: 0, cap: true};
      this.start = {taper: 0, cap: true};
      this.thinning = 0.5;
      this.easing = (t) => t * t;
      this.smoothing = 0.1;
      this.simulatePressure = true;
    }
  }
}
