import React, {useEffect, useRef, useState} from "react";
import {createConnection} from "../../services/fetch";
import {useNavigate, useParams} from "react-router-dom";

import {getStroke} from "perfect-freehand";
import Opts from "../../services/opts";
import Date from "moment/moment";

export default function Sujet(props) {

  const [lines, setLines] = useState([]);
  const [tm, setTm] = useState(Date.now());
  const [rec, setRec] = useState(false);
  const [press, setPress] = useState('');
  let temp = 0;
  let se = 0;
  let currentLine = 0;
  const [line, setLine] = useState(0);
  const llns = [];
  const repl = () => {
    temp += 3;

    const lns = llns.map((a, i) => {
      if (i === currentLine) {


        a.d = getSvgPathFromStroke(getStroke(a.points.filter(t => t.tm < temp), a.opts));
        // console.log(a.d);
        setPress(temp);
        if (a.delta < temp) {

          i == results && clearInterval(se);

          setRec(true);
          currentLine++;
          temp = 0;

//console.log(lines);
        }
      }
      return a;
    })
    //console.log(llns.length,lns.length);
    setLines(lns);
  }
  const replay = () => {

    console.log('repl');
    currentLine = 0;
    //  setLines(strokes.map(a=>a.d=''));
    setTm(Date.now());
    se = setInterval(repl, 2);
    //setPress(press+1);
  }
  let results = 0;
  const pref = useRef(null);
  const {idS} = useParams();
  const [strokes, setStrokes] = useState([]);
  const [bunch, setBunch] = useState([]);
  const [page, setPage] = useState(null);
  const navigate = useNavigate();
  const ara = [1];
  const objL = (obj, st, ar = []) => {
    createConnection().get('strokes', 'filter=objet,eq,' + obj + "&page=" + st + ',20').then(a => {
      const recs = a.records.map(t => {


        if (t) {

          let step;

          const line = t.dots ? JSON.parse(t.dots.replace(/}/g, ']').replace(/{/g, '[')) : JSON.parse(t.line);
          if (t.delta) {
            step = t.delta / line.length;

          } else {
            const ll = t.dots ? t.dots.replace(/{{/g, 'M ').replace(/,([0-9|\.]+)}}/g, '').replace(/,([0-9|\.]+)},{/g, ' L ') :
              t.line.replace(/\[\[/g, 'M ').replace(/,([0-9|\.]+)\]\]/g, '').replace(/,([0-9|\.]+)\],\[/g, ' L ');
            pref.current.setAttribute('d', ll);

            step = pref.current.getTotalLength() / line.length;
          }

          const black = t.color.replace('rgba(', '').replace(')', '').split(',').map(a => a / 255);

          const l = Math.max(black[0], black[1], black[2]);
          const s = l - Math.min(black[0], black[1], black[2]);
          t.sat = 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0);

          const rt = line.map((l, y) => {
            const kl=[];
            const ttm = y === 0 ? 0 : Math.round(y * step);
            kl.x = l[0];
            kl.y = l[1];
            kl.pressure = l[2];
            kl.tm = ttm;

            return kl;

          });
          const opts = new Opts(t.width ? t.width : 4, t.type);

          t.dd = getSvgPathFromStroke(getStroke(line, opts));
          //    console.log(t.dd)
          t.d = '';
          t.points = rt;
          t.opts = opts;
          console.log(t);
          llns.push(t);

        }
        return t;
      });

      //const opts = new Opts(size,brush);

      const as = ar.concat(recs);

      // console.log(as);
      (st == 1 && !ara.includes(st + 1)) && console.log('loading')
      if (!ara.includes(st + 1)) {
        setStrokes(as);
      } else console.log('dddddoub', st, ara);

      if (a.records.length == 20 && !ara.includes(st + 1)) {
        st == 1 && replay();
        results = st == 1 ? results + a.results : results;
        ara.push(st + 1);
        objL(obj, st + 1, as);
      } else {
        console.log(ara, strokes, st)
      }
    });
  }
  const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  useEffect(() => {


    if (!page && idS) {
      createConnection().get('sujet/' + idS, 'join=objet&exclude=code').then(a => {

          setPage(a);
          //a.objet.map(u => )
          objL(a.objet[0].id, 1);

        }
      );

    }


  });
  return props.st ?
    (
      <div className=" w-40 h-40 p-2"
           style={{backgroundSize: 'cover', backgroundImage: props.st.thumb ? 'url(' + props.st.thumb + ')' : 'none'}}
           onClick={() => navigate('/sujet/' + props.st.id)}>

        {props.st.name}<br/>


      </div>
    )
    :
    (
      <div style={{backgroundColor: '#bbb'}}>

        {page && (
          <>

            <svg style={{backgroundColor: 'rgb(224 226 212)', width: 'auto', height: '100vh', margin: 'auto'}}
                 viewBox={'0 0 ' + page.size.replace(',', ' ')}>
              <path ref={pref} d=''/>

              {
                page.objet && page.objet.map((aa, i) => (
                  <>
                    <g key={aa.id} filter="url(#f1)" opacity='0.5'>
                      {lines.sort((a, b) => a.id - b.id).filter(y => y.objet === aa.id && y.sat > 10).map((u, ii) =>
                        (
                          <path fill={u.color} key={ii} d={u.d}/>

                        )
                      )

                      }

                    </g>
                    <g key={aa.id + '_'}>
                      {lines.sort((a, b) => a.id - b.id).filter(y => y.objet === aa.id && y.sat <= 10).map((u, ii) =>
                        (
                          <path fill={u.color} key={ii + '_'} d={u.d} filter="url(#pen)"/>

                        )
                      )

                      }

                    </g>
                  </>
                ))
              }
              <filter id="f1" x="0" y="0" xmlns="http://www.w3.org/2000/svg">

                <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="2"
                              stitchTiles="stitch" result="f1"></feTurbulence>
                <feColorMatrix type="matrix"
                               values="0 0 0 0 0, 0 0 0 0 0, 0 0 0 0 0, 0 0 0 -1.7 1.7" result="f2"></feColorMatrix>
                <feComposite operator="in" in2="f2" in="SourceGraphic"
                             result="f3"></feComposite>

                <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="3"
                              result="noise"></feTurbulence>
                <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="3.5"
                                   in="f3" result="f4"></feDisplacementMap>
                <feGaussianBlur in="SourceGraphic" stdDeviation="6"/>
              </filter>
              <filter x="0%" y="0%" width="100%" height="100%"
                      filterUnits="objectBoundingBox" id="pen">
                <feTurbulence  type="fractalNoise" baseFrequency="0.8" numOctaves="2"
                              stitchTiles="stitch" result="f1"></feTurbulence>
                <feColorMatrix  type="matrix"
                               values="0 0 0 0 0, 0 0 0 0 0, 0 0 0 0 0, 0 0 0 -1.7 1.7" result="f2"></feColorMatrix>
                <feComposite  operator="in" in2="f2" in="SourceGraphic"
                             result="f3"></feComposite>
                <feTurbulence  type="fractalNoise" baseFrequency="0.8" numOctaves="3"
                              result="noise"></feTurbulence>
                <feDisplacementMap  xChannelSelector="R" yChannelSelector="G" scale="3.5"
                                   in="f3" result="f4"></feDisplacementMap>
              </filter>
            </svg>
          </>
        )}
      </div>
    )


}
