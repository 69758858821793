import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'
import { ChevronDownIcon, PencilIcon, Bars4Icon } from '@heroicons/react/20/solid'

export default function MenuM() {
  const [open, setOpen] = useState(false)

  return (
    <div className="absolute bottom-2 right-2">
      <div className='w-8 h-8 p-2 rounded-full border-1 border-gray-400'>
      <Bars4Icon  onClick={() => setOpen((open) => !open)}></Bars4Icon>
      </div>
      <Transition show={open}>
        <div
          className={clsx([
            // Base styles
            'absolute bottom-8 w-48 border transition ease-in-out',
            // Shared closed styles
            'data-[closed]:opacity-0',
            // Entering styles
            'data-[enter]:duration-100 data-[enter]:data-[closed]:-translate-y-full',
            // Leaving styles
            'data-[leave]:duration-300 data-[leave]:data-[closed]:translate-y-full',
          ])}
        >

          <div className='w-8 h-8 p-2 rounded-full border-1 border-gray-400'>
            <Bars4Icon></Bars4Icon>
          </div>
          <div className='w-8 h-8 p-2 rounded-full border-1 border-gray-400'>
            <Bars4Icon></Bars4Icon>
          </div>
          <div className='w-8 h-8 p-2 rounded-full border-1 border-gray-400'>
            <Bars4Icon></Bars4Icon>
          </div>
        </div>
      </Transition>
    </div>
  )
}
