import React, {useState, useEffect} from "react";

import Esket from "./esket";
import {DomProvider, useDom} from "../context/dom";
import MenuB from "./menus/menuB";


const CanvL = (pro) => {
const dom = useDom();
  const [ready, setReady] = useState(null);

  useEffect(() => {
    const CanvasKitInit = require('../js/canvaskit')
  CanvasKitInit().then((CanvasKit) => {

      setReady(CanvasKit);
      const htmlCanvasElement = document.createElement("canvas");
      const rootElement = document.getElementById("ttid");
      htmlCanvasElement.width = window.innerWidth;
      htmlCanvasElement.height = window.innerHeight;
      htmlCanvasElement.id='foo';
      rootElement.prepend(htmlCanvasElement);

    });

    return () => {
      console.log('og');
      //cancelAnimationFrame(requestRef.current);
    }

    /* //  */
    //   rafId.current = requestAnimationFrame(drawFrame)
  }, []);


  return (
    <>
      {
        ready ? (
<>
           <Esket prop={ready} pro={pro.user}/>

        </>
        ) : (
          <span>notready</span>

        )


      }

    </>


  );
}
export default CanvL;
