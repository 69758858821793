import React, {useEffect, useState} from "react";
import {createConnection} from "../../services/fetch";
import {useNavigate, useParams} from "react-router-dom";
import Sujet from "./sujet";


export default function Story(props) {
  const { id } = useParams();

  const [page, setPage] = useState(null);
  const navigate = useNavigate();
  const ww=props.w<480   ? Math.floor(window.outerWidth) : 200;
  const hh=props.w<480   ? '100vh' : '200px';
  useEffect(() => {

    if(!page && props.st && props.st.sujet.length>0)   {

      createConnection().get('sujet/'+props.st.sujet[0].id,'').then(a=> {
          setPage(a);

        }
      );

    }
    if(!page && id)   {
      createConnection().get('story/'+id,'join=sujet&include=name,description,sujet.name,sujet.id,sujet.thumb').then(a=> {

setPage(a);
        }
      );

    }


  });
  return  props.st ?
        (
          <div  style={{width:ww+'px',height:hh, backgroundSize:'cover',backgroundImage: page? 'url('+page.thumb+')' : 'none'}}
               onClick={()=>navigate('/story/'+props.st.id)}>

            {props.st.name}<br/>
            {props.st.sujet.length}

          </div>
        )
        :
        (
         <div>

           {page && (
             <>
               {page.name}
               <br/>
               {page.description}
               <div className="flex flex-wrap">
                 {page.sujet.map((a,i)=>
                   (


                     <Sujet key={a.id}  st={a}/>

                   )
                 )


                 }

               </div>
             </>
           )}
         </div>
        )


}
