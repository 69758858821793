//import S3 from "react-aws-s3";
//import S3FileUpload from 'react-s3';
//import {ListBucketsCommand, PutObjectCommand, S3Client} from "@aws-sdk/client-s3";

//import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export function createConnection() {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  return {

  thumb(canvas,id) {
      canvas.toBlob(blob => {
        const path = 'f_' + id + '.png';
        const myFile = new File([blob], path, {
          type: blob.type
      });
        const AWS_ACCESS_KEY_ID = '7b161be99ba96be249024385793cbd41';
        const AWS_SECRET_ACCESS_KEY = 'ed5c0f21dfeefff66dd3067ac6953a4d035fd87403e59c7676051a8c013ab4fb';
        const AWS_ENDPOINT= 'https://03436cc6cf076e96702ae26ca015c79e.r2.cloudflarestorage.com/vids';
        /*
          this.S3 = new S3Client({
            region: 'auto',
            endpoint: AWS_ENDPOINT,
            credentials: {
              accessKeyId: AWS_ACCESS_KEY_ID,
              secretAccessKey: AWS_SECRET_ACCESS_KEY,
            },
          });
          //https://03436cc6cf076e96702ae26ca015c79e.r2.cloudflarestm/vids

          /*
               const config = {
                 bucketName: 'vids',
                 region: 'auto',
                 accessKeyId: AWS_ACCESS_KEY_ID,
                 secretAccessKey: AWS_SECRET_ACCESS_KEY,
                 s3Url: AWS_ENDPOINT
               }
               S3FileUpload.uploadFile(myFile, config)
                 .then((data)=>{
                   console.log(data.location);
                 }ch((err)=>{
                 alert(err);
               })
               /*
                    const ReactS3Client = new S3(config);
                    ReactS3Client
                      .uploadFile(myFile, 'f_'+id+'.png')
                      .then(data => console.log(data))
                      .catch(err => le.error(err))
                    /*
                          getSignedUrl(
                            S3,
                            new PutObjectCommand({
                              Bucket: 'vids',
                              Key: path,
                            }),
                            {
                              expiresIn: 60 * 60 * 24 * 7, //                }
                          ).then((url) =>{
                            /*
                            console.log('url',url);
                            const response =  fetch(url, {
                              method: "PUT",
                              headers: {
                                "Content-Type": 'image/png'
                              },
                              body: myFile
                            });
                            response.json().then(f=>{

                              console.log(f);

                            })



                          })


                          */





        //const tt = this.r2Service.getS(path, myFile, 'image/png' );
        //console.log(tt);
        // @ts-ignore
        //this.restService.updateItem('sujet', this.sujet!.id, {thumb: 'https://vids.facereplays.com/f_' + this.sujet!.id.toString() + '.png'})



      });





    },


    update(node, id, data) {

      fetch('https://buben-sha.herokuapp.com/api/ufront/records/' + node + '/' + id,
        {
          method: "PUT",
          body: JSON.stringify(data),

        headers: {
        'x-apikey': 'today',

      }
        }
      )
        .then(response => response.json())
        .then(data => console.log(data));


    },
    insert(node, data) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", 'Bearer ' + process.env.REACT_APP_access_token);
      myHeaders.append("Accept", "application/json");
     return fetch('https://buben-sha.herokuapp.com/api/records/' + node,
        {
          method: "POST",
          body: JSON.stringify(data),
headers: myHeaders
        }
      )
        .then(response => response.json())



    },
    getPromise(ob,filter) {

      return fetch('https://buben-sha.herokuapp.com/api/records/'+ob+'?' + filter)
        .then(response => response.json())



    },
  async  get(ob,filter) {

      try{
        return await fetch('https://buben-sha.herokuapp.com/api/records/'+ob+'?' + filter)
        .then(response => response.json()).catch(a=>console.log(a))

    }catch(e){
        return null;

      }

  },
    connect(filter) {

return fetch('https://buben-sha.herokuapp.com/api/records/merop?' + filter)
        .then(response => response.json())



    },
    disconnect() {
      console.log('❌ Disconnected from');
    }
  };
}
