import React, {createContext, useEffect, useRef, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

//import {useTelegramWebApp} from '@telegram-web-app/react';

import Dashboard from "./components/dashboard";

import Esket from "./components/esket";
import Canv from "./components/canv";
import Esketchos from "./components/esketchos";
import Story from "./components/thumbs/story";

import Sket from "./components/sket";
import SujetP from "./components/thumbs/sujetP";

import CanvL from "./components/canvL";
import {DomProvider} from "./context/dom";


export const tenant = "6nz5z4rb";
export default function App() {




  return (
    <Router>


          <div className="checker_" style={{position: 'relative', justifyContent: 'center'}} id="ttid">
            <div style={{position: 'absolute', top: 0}}></div>
            <DomProvider>
              <Routes>
              <Route path="/" element={window.Telegram.WebApp.initDataUnsafe.user ? <Esket  /> :<Esketchos /> }/>
              <Route path="draw" element={<CanvL  />}/>
              <Route path="story/:id" element={<Story/>}/>
              <Route path="sujet/:idS" element={<SujetP/>}/>
              <Route path="sujetP/:id" element={<Sket/>}/>
              <Route path="sujet_/:idS" element={<Canv/>}/>
              <Route
                path="/dashboard"
                element={
                  <RequireAuth>
                    <Dashboard/>
                  </RequireAuth>
                }
              />
            </Routes></DomProvider>
          </div>


    </Router>
  );
}


function RequireAuth({children}) {
  let location = useLocation();


  return children;
}
